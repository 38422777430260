import React, { useState, useEffect } from 'react';
import { Box, VStack, Text, Button, IconButton, Textarea } from '@chakra-ui/react';
import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import HighlightsSection from "./HighlightsSection";
import PersonalizedSection from "./PersonalizedSection";




const FloatingSidebar = ({sections, editableData, editMode, setEditMode, 
    status, personalizedStatus, responseData, results, selectedBoxes, 
    handleCheckboxChange, handleSetSegments, calculateTotalDuration, animateInputs, handleSectionTextChange}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleSectionClick = (index) => {
    console.log('section clicked', index)
    const sectionElement = document.getElementById(`section-${index}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
        // const topOffset = sectionElement.getBoundingClientRect().top + window.scrollY - 10; // Adjust the value to leave a 10px margin
        // window.scrollTo({
        //   top: topOffset,
        //   behavior: 'smooth'
        // });
    }
  };

  useEffect(() => {
    console.log('SECTIONSsss', sections)
  }, [sections])
  return (
    <div>
      <div className={`floating-sidebar ${isOpen ? 'open' : ''}`}>
        {/* <Button onClick={toggleSidebar} position="absolute" right="10px" top="10px">
          <FontAwesomeIcon icon={faGripLines} />
        </Button> */}
        <VStack spacing={4} align="stretch" p={4}>
            
        
      </VStack>
        <Box
          width="200px"
          padding="10px"
          paddingBottom={"0px"}
          bgColor="rgba(255, 255, 255, 0.5)"
          position="absolute"
          top="25%"
          left="0"
          zIndex="-1"
          marginLeft="20px"
          borderRadius="10px"
          backdropFilter="blur(10px)"
          boxShadow="0 0 10px rgba(0, 0, 0, .1)"
        >
        {sections.map((section, index) => (
          section.hidden ? null : (
            <div key={index} className="sidebar-section" onClick={() => handleSectionClick(index)}>
              <div className="sidebar-thumbnail">
                {responseData && personalizedStatus === "completed" && (
                  <>
                    {section.section_type === "videos" && results ? (
                      <HighlightsSection
                        key={index}
                        results={results}
                        selectedBoxes={selectedBoxes}
                        editMode={editMode}
                        handleCheckboxChange={handleCheckboxChange}
                        handleSetSegments={handleSetSegments}
                        calculateTotalDuration={calculateTotalDuration}
                        animateInputs={animateInputs}
                        isThumbnail={true}
                        index={index}
                      />
                    ) : (
                      <PersonalizedSection
                        key={index}
                        sectionIndex={index}
                        sectionTitle={section.section_title}
                        sectionData={section.contents}
                        isEditing={editMode}
                        handleSectionTextChange={handleSectionTextChange}
                        isThumbnail={true}
                        index={index}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="sidebar-title">{section.section_title}</div>
            </div>
          )
        ))}
        </Box>
      </div>
    </div>
  );
};

export default FloatingSidebar;