// src/Login.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebaseConfig";

import { useToast } from "@chakra-ui/react";

function Login({ onLogin }) {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false); // New state to toggle between login and sign up
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [accessCode, setAccessCode] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Set the form type based on the current URL path
    if (location.pathname === "/signup") {
      setIsSignUp(true);
      setIsForgotPassword(false);
      setErrorMessage("");
    } else {
      setIsSignUp(false);
      setIsForgotPassword(false);
      setErrorMessage("");
    }
  }, [location.pathname]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let userId = "";
      setErrorMessage(""); // Clear any existing errors
      if (isSignUp) {
        // Verify access code
        const encoder = new TextEncoder();
        const data = encoder.encode(accessCode);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashedAccessCode = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        
        const correctHashedAccessCode = '0fd685b2d100d30e0f510dd784452bc9225efc95235e98c7491404768ff656bb';

        if (hashedAccessCode !== correctHashedAccessCode) {
          setErrorMessage("Invalid access code. Please try again or book a demo to get the code.");
          return;
        }

        // Sign up process
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        console.log("Signed up user:", userCredential.user);

        await sendEmailVerification(userCredential.user);
        alert(
          "A verification email has been sent. Please check your email and verify your account.",
        );

        userId = userCredential.user.uid;
        // Assuming the `add_api_user` endpoint expects a JSON body with the userId
        const response = await fetch(
          `https://api.usemica.com/add_api_user?user_id=${encodeURIComponent(userId)}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (!response.ok) {
          throw new Error("Failed to add API user");
        }

        userId = userCredential.user.uid;
      } else {
        // Login process
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        // console.log('Logged in user:', userCredential.user);
        userId = userCredential.user.uid;
      }
      onLogin(userId);
      // const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // console.log('Logged in user:', userCredential.user);
      // onLogin();
    } catch (error) {
      console.error("Error logging in:", error.message);
      if (error.code === "auth/email-already-in-use") {
        // Update state to show specific error message
        setErrorMessage("An account with this email already exists.");
      } else if (error.code === "auth/invalid-credential") {
        setErrorMessage("Invalid Email or Password");
      } else {
        // For other errors, you might want to display a generic message
        setErrorMessage("An error occurred. Please try again.");
      }
    }
  };

  const handlePasswordReset = async (email) => {
    // console.log('yes')
    setIsForgotPassword(true);
    if (!email) {
      setErrorMessage("Please enter your email address to reset your password.");
      return;
    }
    try {
      // console.log('yess')
      await sendPasswordResetEmail(auth, email);
      // alert('Password reset email sent! Check your inbox.');
      toast({
        title: "Password reset email sent.",
        description: "Check your inbox for further instructions.",
        status: "success",
        duration: 9000,
        isClosable: false,
        position: "top", // or "bottom" depending on your preference
      });
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setErrorMessage("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <div style={styles.page} className="ph-no-capture">
      <div style={styles.glassmorphism}>
        <img
          src={`${process.env.PUBLIC_URL}/mainlogo.png`}
          alt="Logo"
          className="sidebar-logo login-logo-placement"
        />
        <p className="login-signup-p">
          {isForgotPassword ? "Forgot Password" : isSignUp ? "Sign Up" : "Login with Email"}
        </p>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleLogin} style={styles.form}>
          {isSignUp && !isForgotPassword && (
            <input
              type="text"
              name="name"
              placeholder="Name"
              style={styles.input}
              className="input"
              // This input is a dummy and won't affect form submission
            />
          )}

          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            style={styles.input}
            className="input"
          />
          {!isForgotPassword && (
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              style={styles.input}
              className="input"
            />
          )}
          {isSignUp && !isForgotPassword && (
            <>
              <input
                type="text"
                name="accessCode"
                value={accessCode}
                onChange={(e) => setAccessCode(e.target.value)}
                placeholder="Access Code"
                style={styles.input}
                className="input"
              />
              <p style={styles.helpText}>
                Don't have an access code?{" "}
                <a
                  href="https://calendly.com/achyutaiyengar/mica-15-minute-demo"
                  style={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book a demo
                </a>{" "}
                to get one.
              </p>
            </>
          )}
          {isSignUp && !isForgotPassword && (
            <p style={styles.termsText}>
              By signing up, you are agreeing to the{" "}
              <a
                href="https://usemica.com/terms.html"
                style={styles.link}
                target="_blank"
                rel="noopener noreferrer">
                Mica Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://usemica.com/privacy.html"
                style={styles.link}
                target="_blank"
                rel="noopener noreferrer">
                Privacy Policy
              </a>
              .
            </p>
          )}
          {!isForgotPassword && (
            <button type="submit" className="login-button">
              {isSignUp ? "Sign Up" : "Login"}
            </button>
          )}
          <p
            style={styles.toggleFormText}
            onClick={() => isSignUp ? navigate("/login") : window.location.href = 'https://forms.gle/tx91LpN75y3B6ZDf9'}>
            {/* {isSignUp ? 'Already have an account? Login' : 'Don\'t have an account? Sign Up'} */}
            {isSignUp ? (
              <span>
                Already have an account?{" "}
                <span style={{ color: "blue", textDecoration: "underline" }}>Login</span>
              </span>
            ) : (
              <span>
                Don't have an account?{" "}
                <span style={{ color: "blue", textDecoration: "underline" }}>Request an Account</span>
              </span>
            )}
          </p>

          <p
            className="forgot-password"
            onClick={() => handlePasswordReset(email)}
            style={styles.toggleFormTextForgotPass}>
            Forgot Password?
          </p>
          {/* </div> */}
        </form>
      </div>
    </div>
  );
}

const styles = {
  toggleFormText: {
    cursor: "pointer",
    color: "rgb(67, 67, 67)", // Change as per your color scheme
    marginTop: "15px",
    fontFamily: "Lato",
  },
  toggleFormTextForgotPass: {
    cursor: "pointer",
    color: "blue", // Change as per your color scheme
    marginTop: "15px",
    fontFamily: "Lato",
  },
  page: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: "url(lobtest.png)", // Or any background color
    backgroundSize: "cover",
  },
  glassmorphism: {
    background: "rgba(255, 255, 255, 1)",
    // boxShadow:  '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
    boxShadow: "rgb(154 154 164 / 37%) 0px 8px 32px 0px",
    backdropFilter: "blur( 15.5px )",
    padding: "4rem",
    borderRadius: "10px",
    //   background: 'rgba(255, 255, 255, 0.2)',
    //   boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
    //   backdropFilter: 'blur(15px)',
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
  },
  // input: {

  //   width: '100%',
  //   padding: '10px',
  //   margin: '5px 0',
  //   backgroundColor: 'none',
  //   border: '1px solid lightgrey',
  //   borderRadius:'5px',
  //   fontFamily:'Lato',
  //   fontSize: '16px',
  // },
  termsText: {
    fontSize: "12px",
    textAlign: "center",
    margin: "10px 0",
  },
  link: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },
  helpText: {
    fontSize: "12px",
    textAlign: "center",
    margin: "5px 0",
    color: "rgb(67, 67, 67)",
  },
};

export default Login;
