import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { PostHogProvider} from 'posthog-js/react';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const posthog_options = {
  api_host: "https://ph.usemica.com",
  // api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const customTheme = extendTheme({
  styles: {
    global: {
      // Custom global styles or overrides
      "html, body": {
        fontFamily: null,
        lineHeight: "1.2",
        // Add other base styles as needed
      },
      // Add more selectors as needed
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider resetCSS={false} theme={customTheme}>
    <React.StrictMode>
      <BrowserRouter>
        <PostHogProvider 
          apiKey="phc_Fuf7chAyzJIASOLfXqumlCjqPraSIklLA0t3BhHcqRx"
          // apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={posthog_options}
        >
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </PostHogProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ChakraProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
