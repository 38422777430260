import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  Input,
  List,
  ListIcon,
  ListItem,
  Text,
  useClipboard,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  CopyIcon,
  CheckIcon,
  ViewIcon,
  ViewOffIcon,
  LockIcon,
  MinusIcon,
} from "@chakra-ui/icons";

function ApiKeyInfo() {
  return (
    <Box mb={8} width="100%">
      <List spacing={2} textAlign="left">
        <ListItem>
          <ListIcon as={MinusIcon} />
          Your API key is unique and should be kept secure.
        </ListItem>
        <ListItem>
          <ListIcon as={MinusIcon} />
          Do not share your API key with others or expose it in client-side
          code.
        </ListItem>
        <ListItem>
          <ListIcon as={MinusIcon} />
          Include your API key in each request as the <code>api_key</code> query
          parameter.
        </ListItem>
      </List>
    </Box>
  );
}

function ApiKeyDisplay({ apiKey }) {
  const [showKey, setShowKey] = useState(false);
  const { hasCopied, onCopy } = useClipboard(apiKey);

  return (
    <Card
      bg={useColorModeValue("white", "gray.800")}
      boxShadow="sm"
      width="100%"
    >
      <CardHeader>
        <Heading size="md">Your API Key</Heading>
        <Text>View and copy your API key</Text>
      </CardHeader>
      <CardBody>
        <Flex alignItems="center" mb={4}>
          <LockIcon mr={2} color="gray.500" />
          <Input
            type={showKey ? "text" : "password"}
            value={apiKey}
            readOnly
            fontFamily="mono"
            flexGrow={1}
            mr={2}
          />
          <IconButton
            icon={showKey ? <ViewOffIcon /> : <ViewIcon />}
            aria-label="Show/Hide API Key"
            variant="outline"
            size="sm"
            onClick={() => setShowKey(!showKey)}
            mr={2}
          />

          <IconButton
            icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
            aria-label="Copy API Key"
            variant="outline"
            size="sm"
            onClick={onCopy}
            colorScheme={hasCopied ? "green" : "gray"}
          />
        </Flex>
      </CardBody>
    </Card>
  );
}

export default function ApiKeyPage({ apiKey }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bg={useColorModeValue("gray.100", "gray.900")}
      p={8}
    >
      <VStack spacing={6} width="100%" maxWidth="3xl">
        <Heading as="h1" size="xl" mb={6}>
          API Key Management
        </Heading>
        <ApiKeyInfo />
        <ApiKeyDisplay apiKey={apiKey} />
      </VStack>
    </Box>
  );
}
