import React from "react";
import { Box, Checkbox } from "@chakra-ui/react";

const HighlightsSection = ({
  results,
  selectedBoxes,
  editMode,
  handleCheckboxChange,
  handleSetSegments,
  calculateTotalDuration,
  animateInputs,
  index,
  isThumbnail = false,
}) => {
  return (
    <div id={`section-${index}`} style={{scrollMarginTop: '330px'}}>
      <div
        className={`subheader-text ${
          animateInputs
                  ? "fade-in-animation-slow fade-in-animation-delay-2"
                  : ""
              }`}
            >
              <p className={isThumbnail ? "thumbnail-headline" : "section-headline"}>Highlights From Your Call</p>
      </div>
      <div
        className={`${isThumbnail ? 'thumbnail-grid-container' : 'grid-container'} ${
          animateInputs
            ? "fade-in-animation-slow fade-in-animation-delay-3"
            : ""
        }`}
      >
        {(editMode && !isThumbnail ? results : results.filter(result => selectedBoxes.includes(result.splice_id))) // if edit mode, show all results, otherwise show only the selectedBoxes
          .map(
            (
              { topic, signedUrl, cutData, splice_id, sharing, thumbnail },
              index
            ) => {
              const duration = calculateTotalDuration(cutData);
              const formattedDuration = new Date(duration * 1000)
                .toISOString()
                .substring(11, 19); // Converts seconds to HH:MM:SS format
              return (
                <div
                  key={splice_id}
                  className={`${isThumbnail ? 'thumbnail-grid-item' : 'grid-item'} ${
                    editMode && selectedBoxes.includes(splice_id)
                      ? "selected-item"
                      : ""
                  }`}
                  onClick={
                    editMode
                      ? (e) => handleCheckboxChange(e, topic, splice_id)
                      : () => handleSetSegments(topic, cutData)
                  }
                >
                  {editMode && !isThumbnail && (
                    <input
                      type="checkbox"
                      className="select-box"
                      checked={selectedBoxes.includes(splice_id)}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: 10,
                      }}
                      onChange={(e) =>
                        handleCheckboxChange(e, topic, splice_id)
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  )}
                  <div
                    className={`image_div ${
                      editMode && !selectedBoxes.includes(splice_id)
                        ? "unselected-thumbnail"
                        : ""
                    }`}
                  >
                    <img
                      src={
                        thumbnail !== "none"
                          ? thumbnail
                          : "https://static.vecteezy.com/system/resources/previews/011/375/688/original/purple-play-button-free-vector.jpg"
                      }
                      alt={topic}
                    />
                  </div>
                  <p>
                    {topic} - <strong>{formattedDuration}</strong>
                  </p>
                </div>
              );
            }
          )}
      </div>
      {!isThumbnail && <hr className="divider-no-animate" />}
    </div>
  );
};

export default HighlightsSection;
