import React from "react";
import { Box, Text, Input, Textarea } from "@chakra-ui/react";

const EditableText = ({
  value,
  isEditing,
  onChange,
  fontSize,
  fontWeight,
  lineHeight = "1.4",
  marginTop = "0px",
  marginBottom = "0px",
  isThumbnail = false,
}) => {
  return (isEditing && !isThumbnail) ? (
    <Textarea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily="CircularXX, Inter, sans-serif"
      color="var(--font-color)"
      variant="flushed"
      marginBottom="20px"
      borderRadius={"5px"}
      width={"90%"}
      border="1px solid grey"
      padding={"10px"}
    />
  ) : (
    <Text
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily="CircularXX, Inter, sans-serif"
      color="var(--font-color)"
      lineHeight={lineHeight}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {value}
    </Text>
  );
};

const PersonalizedSection = ({
  sectionIndex,
  sectionTitle,
  sectionData,
  isEditing,
  handleSectionTextChange,
  index,
  isThumbnail = false,
}) => {

  if (isThumbnail) {
    return (
      <Box className="personalized-section" mb={10} mx="auto" textAlign="left" marginBottom={"15px"} >
        {/* Render the section title */}
        {sectionTitle && (
          <EditableText
            value={sectionTitle}
            isEditing={isEditing}
            onChange={(newValue) => handleSectionTextChange(sectionIndex, null, "sectionTitle", newValue)}
            fontSize="4px"
            fontWeight="600"
            marginTop={"7px"}
            marginBottom={"3px"}
            isThumbnail={true}
          />
        )}
  
        {/* Render the content based on section type */}
        {sectionData.map((item, index) => (
          <Box
            key={index}
            mb={6}
            lineHeight="1.8"
            maxWidth="100%"
            fontSize={"2px"}
            color="var(--font-color)"
            marginBottom={"5px"}
          >
            <EditableText
              value={item.title}
              isEditing={isEditing}
              onChange={(newValue) => {
                console.log("newValue", newValue);
                handleSectionTextChange(sectionIndex, index, "itemTitle", newValue);
              }}
              fontSize={"2px"}
              fontWeight="600"
              isThumbnail={true}
            />
  
            <EditableText
              value={item.description}
              isEditing={isEditing}
              onChange={(newValue) => {
                console.log("newValue", newValue);
                handleSectionTextChange(sectionIndex, index, "itemDescription", newValue);
              }}
              fontSize="2px"
              // marginTop={"5px"}
              fontWeight="400"
              isThumbnail={true}
            />
          </Box>
        ))}
  
        {/* Add a divider between sections */}
        
      </Box>
    ); 
  }else{



  return (
    <Box className="personalized-section" mb={10} mx="auto" textAlign="left" id={`section-${index}`} style={{scrollMarginTop: '330px'}}>
      {/* Render the section title */}
      {sectionTitle && (
        <EditableText
          value={sectionTitle}
          isEditing={isEditing}
          onChange={(newValue) => handleSectionTextChange(sectionIndex, null, "sectionTitle", newValue)}
          fontSize="42px"
          fontWeight="600"
          marginTop={"140px"}
          marginBottom={"50px"}
        />
      )}

      {/* Render the content based on section type */}
      {sectionData.map((item, index) => (
        <Box
          key={index}
          mb={6}
          lineHeight="1.8"
          maxWidth="100%"
          fontSize={"24px"}
          color="var(--font-color)"
          marginBottom={"30px"}
        >
          <EditableText
            value={item.title}
            isEditing={isEditing}
            onChange={(newValue) => {
              console.log("newValue", newValue);
              handleSectionTextChange(sectionIndex, index, "itemTitle", newValue);
            }}
            fontSize={"24px"}
            fontWeight="600"
          />

          <EditableText
            value={item.description}
            isEditing={isEditing}
            onChange={(newValue) => {
              console.log("newValue", newValue);
              handleSectionTextChange(sectionIndex, index, "itemDescription", newValue);
            }}
            fontSize="24px"
            marginTop={"5px"}
            fontWeight="400"
          />
        </Box>
      ))}

      {/* Add a divider between sections */}
      <hr className="divider-no-animate" />
    </Box>
  );
}};

export default PersonalizedSection;
