import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Text,
  Button,
  Input,
  VStack,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import {
  faArrowUpFromBracket,
  faInfoCircle,
  faTimes,
  faAnglesRight,
  faFile,
  faCloudUploadAlt,
  faCircleUser,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon,} from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserSecret
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";

const Tooltip = ({ children, text }) => (
  <div className="tooltip">
    {children}
    <span className="tooltiptext">{text}</span>
  </div>
);

const Circle = ({ size, top, left }) => (
  <motion.div
    style={{
      width: size,
      height: size,
      borderRadius: "50%",
      backgroundColor: "purple",
      position: "absolute",
      top,
      left,
      opacity: 0.5, // Slightly increased transparency for better visibility
    }}
    animate={{
      x: [0, Math.random() * 200 - 200, Math.random() * 200 - 100, 0],
      y: [0, Math.random() * 200 - 200, Math.random() * 200 - 100, 0],
    }}
    transition={{
      duration: Math.random() * 1 + 4.5, // Longer duration for slower movement
      repeat: Infinity,
      ease: "linear", // Linear movement for constant speed
    }}
  />
);

const AnimatedBackground = () => {
  const circles = Array.from({ length: 15 }, (_, i) => ({
    size: Math.random() * 500 + 20,
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
  }));

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      overflow="hidden"
      zIndex="-1" // Place it behind the content
    >
      {circles.map((circle, index) => (
        <Circle key={index} {...circle} />
      ))}
    </Box>
  );
};

function SubmitVideo(props) {
  const navigate = useNavigate();
  const [animateInputs, setAnimateInputs] = useState(false);
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [showFileName, setShowFileName] = useState(false);
  const [fileName, setFileName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isLinkModalOpen,
    onOpen: onLinkModalOpen,
    onClose: onLinkModalClose,
  } = useDisclosure();
  const [linkInput, setLinkInput] = useState("");
  const toast = useToast();
  const [selectedFileName, setSelectedFileName] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      handleFileSelect({ target: { files: acceptedFiles } });
      setSelectedFileName(acceptedFiles[0].name);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "video/mp4": [".mp4"],
      "video/quicktime": [".mov"],
    },
    maxFiles: 1,
  });

  useEffect(() => {
    setAnimateInputs(true);
  }, []);

  const handleFileSelect = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        props.setVideoFile(file);
        props.setIsFileUploaded(true);
        setSelectedFileName(file.name);
      }
    }
  };

  const handleUploadIconClick = () => {
    if (props.isFileUploaded) {
      props.setVideoLink("");
      props.setIsFileUploaded(false);
    } else {
      document.getElementById("videoFileInput").click();
    }
  };

  const handleNavigateToOtherResult = (isRecent, jobId) => {
    navigate(`/results/${jobId}`);
  };

  const handleUploadButtonClick = () => {
    onOpen(); // Open the modal
  };

  const handleLinkButtonClick = () => {
    onLinkModalOpen();
  };

  const handleLinkInputChange = (e) => {
    setLinkInput(e.target.value);
  };

  const handleLinkSubmit = () => {
    props.setVideoLink(linkInput);
    props.handleOnSubmit();
    onLinkModalClose();
  };

  const {
    isOpen: isLogoutPopoverOpen,
    onOpen: onLogoutPopoverOpen,
    onClose: onLogoutPopoverClose,
  } = useDisclosure();

  const handleLogout = () => {
    // Implement your logout logic here
    console.log("User logged out");
    onLogoutPopoverClose();
    // You might want to redirect the user or update the app state after logout
  };

  return (
    <div id="yolo">
      <AnimatedBackground />
      <div className="content-glass" style={{ height: "100vh", overflowY: "auto" }}>
        <div style={{ position: "sticky", top: 0, left: 0, right: 0, display: "flex", justifyContent: "space-between", alignItems: "center", width: "calc(100% - 40px)", padding: "20px", zIndex: 1000 }}>
          <img
            src={`${process.env.PUBLIC_URL}/mainlogo.png`}
            alt="Logo"
            style={{ height: "30px" }}
          />
          <Popover
            isOpen={isLogoutPopoverOpen}
            onClose={onLogoutPopoverClose}
            placement="bottom-end"
          >
            <PopoverTrigger>
              <div 
                style={{ 
                  width: "30px", 
                  height: "30px", 
                  borderRadius: "50%", 
                  backgroundColor: "#000000",
                  display: "flex", 
                  justifyContent: "center", 
                  alignItems: "center",
                  cursor: "pointer",
                  
                }}
                onClick={onLogoutPopoverOpen}
              >
                <FontAwesomeIcon icon={faUserAstronaut} style={{ color: "#ffffff" , width: "15px", height: "20px"}} />
              </div>
            </PopoverTrigger>
            <PopoverContent
              bg="#2e2e2e"
              color="white"
              borderColor="#2e2e2e"
              fontFamily="CircularXX, sans-serif"
              width="auto"
            >
              <PopoverArrow bg="#2e2e2e" />
              <PopoverCloseButton border={0} color="white" cursor="pointer" />
              <PopoverBody padding="16px">
                <Button
                  onClick={props.handleLogout}
                  variant="solid"
                  size="md"
                  bg="#af00d6"
                  color="white"
                  fontWeight="medium"
                  _hover={{ bg: "#8e00b0" }}
                  width="100%"
                  border="none" // Removed border from the button
                  marginTop={"20px"}
                  cursor={"pointer"}
                  
                >
                  Logout
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </div>

        <div style={{ width: "85%", margin: "0 auto" }}>
          <div className="submit-wrapper">
            <div className="introduction-text">
              <p>Mica Pages</p>
            </div>

            
            {props.errorModalVisible && (
              <p className="error-message">{props.errorModalMessage}</p>
            )}
            <div className="input-group">
              <div className="button-container">
                <button
                  className={`upload-button ${
                    animateInputs
                      ? "fade-in-animation fade-in-animation-delay-1"
                      : ""
                  }`}
                  onClick={handleUploadButtonClick}
                >
                  Upload Sales Call
                </button>
                <input
                  type="file"
                  id="videoFileInput"
                  style={{ display: "none" }}
                  accept=".mp4, .mov"
                  onClick={() => {
                    if (props.isFileUploaded) {
                      setShowFileName(true);
                    }
                    setShowLinkInput(false);
                  }}
                  onChange={(e) => {
                    handleFileSelect(e);
                    setFileName(e.target.files[0].name);
                    setShowFileName(true);
                    setShowLinkInput(false);
                  }}
                />
                <button
                  className={`link-button ${
                    animateInputs
                      ? "fade-in-animation fade-in-animation-delay-2"
                      : ""
                  }`}
                  onClick={handleLinkButtonClick}
                >
                  Insert Link
                </button>
              </div>
            </div>
            <div className="input-container">
              {showFileName && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{ marginRight: "10px" }}
                  />
                  <span>{fileName}</span>
                </div>
              )}
              {showLinkInput && (
                <input
                  type="text"
                  className={`custom-textbox ${
                    animateInputs
                      ? "fade-in-animation fade-in-animation-delay-3"
                      : ""
                  }`}
                  placeholder="Insert video link here"
                  name="video_link"
                  value={props.videoLink}
                  onChange={(e) => props.updateVideoInputValues(e)}
                  style={{ marginTop: "10px" }}
                />
              )}
            </div>
          </div>{!props.isVerified && (
              <Center>
                <Alert
                  status="success"
                  borderRadius={"10px"}
                  fontFamily={"Lato"}
                  textAlign={"left"}
                  marginTop={"20px"}
                  width={"50%"}
                >

                  <AlertIcon />
                  <AlertTitle>Email Verification Sent.</AlertTitle>
                  <AlertDescription>
                    Please verify your email to start creating shorts. Check
                    your spam folder if you don't see a verification.
                  </AlertDescription>
                </Alert>
              </Center>
            )}
          <div className="card-section-container">
            {props.jobs.length > 0 ? (
              <>
                <div>
                  <p
                    className="card-section-titles"
                    style={{ fontFamily: "CircularXX" }}
                  >
                    Recent Calls
                  </p>
                  <div className="large-card-container">
                    {props.jobs
                      .slice(0, 2)
                      .filter((job) => job.thumbnail_url)
                      .map((job, index) => (
                        <div
                          key={index}
                          className="large-card-wrapper"
                          onClick={() =>
                            handleNavigateToOtherResult(false, job.job_id)
                          }
                        >
                          <div className="large-card">
                            <img
                              src={job.thumbnail_url}
                              alt={`Thumbnail for ${job.title}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                          <p
                            className="card-caption"
                            style={{
                              fontSize: "0.9em",
                              textAlign: "left",
                              fontFamily: "CircularXX",
                            }}
                          >
                            {job.title}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  <p
                    className="card-section-titles"
                    style={{ fontFamily: "CircularXX" }}
                  >
                    All Calls
                  </p>
                  <div className="card-grid">
                    {props.jobs
                      .slice(2)
                      .filter((job) => job.thumbnail_url)
                      .map((job, index) => (
                        <div
                          key={index}
                          className="card-wrapper"
                          onClick={() =>
                            handleNavigateToOtherResult(false, job.job_id)
                          }
                        >
                          <div className="card">
                            <img
                              src={job.thumbnail_url}
                              alt={`Thumbnail for ${job.title}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                          <p
                            className="card-caption"
                            style={{
                              fontSize: "0.9em",
                              textAlign: "left",
                              fontFamily: "CircularXX",
                            }}
                          >
                            {job.title}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  backgroundColor: "rgba(255, 255, 255, .9)",
                  borderRadius: "5px",
                  padding: "15px",
                  textAlign: "center",
                  fontFamily: "CircularXX, sans-serif",
                  color: "#666",
                  width: "50%",
                  margin: "0 auto",
                  marginTop: "100px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)"
                }}
              >
                <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
                  No Mica Pages Yet
                </p>
                <p>
                  Get started above by uploading or adding a link to a sales
                  call.
                </p>
              </div>
            )}
          </div>
        
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent
          bg="gray.800"
          color="white"
          fontFamily="CircularXX, sans-serif"
        >
          <ModalHeader fontFamily="CircularXX, sans-serif">
            Upload Sales Call
          </ModalHeader>
          <ModalCloseButton border={0} color="white" cursor="pointer" />
          <ModalBody pb={6}>
            <VStack spacing={4}>
              <Box
                {...getRootProps()}
                w="100%"
                h="200px"
                border="2px dashed"
                borderColor={isDragActive ? "purple.500" : "gray.500"}
                borderRadius="md"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                transition="all 0.2s"
                _hover={{ borderColor: "purple.500" }}
              >
                <input {...getInputProps()} />
                <VStack spacing={2}>
                  <FontAwesomeIcon
                    icon={faCloudUploadAlt}
                    size="3x"
                    color="#805AD5"
                  />
                  <Text textAlign="center" fontFamily="CircularXX, sans-serif">
                    {isDragActive
                      ? "Drop the video file here"
                      : selectedFileName
                      ? `Selected file: ${selectedFileName}`
                      : "Drag and drop your video file here, or click to select"}
                  </Text>
                  <Text
                    fontSize="sm"
                    color="gray.400"
                    fontFamily="CircularXX, sans-serif"
                  >
                    Supported formats: .mp4, .mov
                  </Text>
                </VStack>
              </Box>

              <Input
                id="fileInput"
                type="file"
                accept=".mp4,.mov"
                display="none"
                onChange={(e) => {
                  handleFileSelect(e);
                }}
              />

              <Button
                colorScheme="purple"
                width="20%"
                border="none"
                cursor="pointer"
                onClick={() => {
                  props.handleOnSubmit();
                  onClose();
                }}
                fontFamily="CircularXX, sans-serif"
              >
                Submit
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* New Link Input Modal */}
      <Modal isOpen={isLinkModalOpen} onClose={onLinkModalClose} size="xl">
        <ModalOverlay />
        <ModalContent
          bg="gray.800"
          color="white"
          fontFamily="CircularXX, sans-serif"
        >
          <ModalHeader fontFamily="CircularXX, sans-serif">
            Insert Video Link
          </ModalHeader>
          <ModalCloseButton border={0} color="white" cursor="pointer" />
          <ModalBody padding="40px">
            <VStack spacing={4}>
              <Input
                placeholder="Enter video link here"
                color="white"
                size="lg"
                name="video_link"
                value={props.videoLink}
                onChange={(e) => props.updateVideoInputValues(e)}
                fontFamily="CircularXX, sans-serif"
                _hover={{ borderColor: "purple.500" }}
                transition="border-color 0.2s"
              />
              <Text
                fontSize="sm"
                color="gray.400"
                fontFamily="CircularXX, sans-serif"
                textAlign="center"
              >
                We accept video links with https://www. from Zoom, Chorus,
                Youtube, Vimeo, Google Drive, Google Cloud Storage, or
                MeetRecord. Other videos can be uploaded from your computer
                using the upload video button.
              </Text>
              <Button
                colorScheme="purple"
                width="20%"
                border="none"
                cursor="pointer"
                onClick={handleLinkSubmit}
                fontFamily="CircularXX, sans-serif"
              >
                Submit
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

const styles = {
  pnge1: {
    backgroundImage: "url(4.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  pnge2: {
    backgroundImage: "url(1.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  pnge3: {
    backgroundImage: "url(2.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  pnge4: {
    backgroundImage: "url(3.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
};

export default SubmitVideo;
