import React, { useState, useEffect, useRef, useCallback } from "react";
import "./App.css";
import Siriwave from "react-siriwave";
import Results from "./components/Results";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import SubmitVideo from "components/SubmitVideo";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseConfig";
import { signOut } from "firebase/auth";
import Login from "./components/Login";
import VideoEditTest from "./components/VideoEditTest";
import { Center, CircularProgress } from "@chakra-ui/react";
import posthog from "posthog-js";
import ApiKeyPage from './components/ApiKeyPage';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  //Submit Video States
  const [sliderValue, setSliderValue] = useState(10);
  const [min_length_sliderValue, setmin_length_SliderValue] = useState(3);
  const [buffer_sliderValue, setbuffer_SliderValue] = useState(20);
  const [videoLink, setVideoLink] = useState("");
  const [prompt, setPrompt] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  //Other States
  const [speed_value, setSpeedValue] = useState(0.02);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userIdToken, setUserIdToken] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [isVerified, setIsVerified] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [isPreview, setIsPreview] = useState(true);
  const [videoType, setVideoType] = useState("other");
  const [sidebarPageNum, setSidebarPageNum] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: "https://storage.googleapis.com/mica-uploaded-videos/Early%20Challenges%20and%20Opposition%20to%20the%20Golden%20Gate%20Bridge%20Construction_output_9dcefdafe58f41538bd53de213fa37af_20240412_032656_output.mp4?Expires=1713822292&GoogleAccessId=code-on-original-vm%40robotic-tide-405500.iam.gserviceaccount.com&Signature=U9egSJ8BCC%2BiSKI95bRTnzAs7jw%2FG%2F%2FgcrDVnG5ku4%2Brm6GTO4hH2yq16MBhdUa15cyUeRSJwwhKMh07PBa2Qdd1opkaxrEF10iM5r9NiHh%2BxZWy1TiFm7OOjXbhsGWlGKY7Q9f6UPIjWmoAMdXt8JbS5%2FrqXNmQI0QWsmEk%2F1N4UGEetrBCPfn6oOw2q1YxXVz%2F5VTUGdOlMvKeqBkkYlH6RYXQPUNF1Fy5yVVrutyO816g4MFJONhYK8srpm9YbjIyTkd8NPecr1BuaVpxtWDO%2FhkPYTxvcbWotfYFv5gY6KVcXguXqpZTPZEfsue2WNBGtDNIECeRTSiR4NpXpw%3D%3D",
        type: "video/mp4",
      },
    ],
  };


  // const isResultsPage = location.pathname.includes("/results") || location.pathname.includes("/preview"); ;
  const isResultsPage = location.pathname.startsWith("/results");
  console.log('IS IT RESULTS, ', isResultsPage)

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("waiting", () => {
      // videojs.log('player is waiting');
    });

    player.on("dispose", () => {
      // videojs.log('player will dispose');
    });
  };

  const jobIdRef = useRef(null);


  const initializeUserAndFetchJobs = async () => {
    let user_id = "";

    const user = await new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          user_id = user.uid;
          resolve(user);
        } else {
          resolve(null);
        }
        unsubscribe();
      });
    });

    console.log("USER:", user);
    setLoading(false)

    if (user) {
      setUserIdToken(user.uid);
      setIsAuthenticated(true);
      posthog.identify(user.email);

      if (!user.emailVerified) {
        setIsVerified(false);
      }

      try {
        // Fetch the API key for the user from your backend
        const apiKeyResponse = await fetch(
          `https://api.usemica.com/get_api_user_key/${encodeURIComponent(
            user.uid
          )}`
        );
        if (!apiKeyResponse.ok) {
          throw new Error("Failed to fetch API key");
        }
        const apiKeyData = await apiKeyResponse.json();
        const userApiKey = apiKeyData.api_key;
        setApiKey(userApiKey);

        // Fetch jobs and set in sidebar
        await fetchJobs(userApiKey);
      } catch (error) {
        console.error("Error in operations:", error);
      }
    } else {
      setIsAuthenticated(false);
    }

    setSpeedValue(0.02);
  };

  // Memoize function to use in useEffect dependency array
  const fetchJobs = useCallback(async (currentApiKey = apiKey) => {
    try {
      console.log("fetching jobs");
      const jobsResponse = await fetch(
        `https://api.usemica.com/jobs_with_thumbnails?api_key=${encodeURIComponent(
          currentApiKey
        )}&page=${sidebarPageNum}&page_size=20&include_errors=false`
      );
      if (!jobsResponse.ok) {
        throw new Error("Failed to fetch jobs");
      }
      const jobsData = await jobsResponse.json();
      console.log("jobsData", jobsData);
      setJobs(jobsData.jobs || []); // Ensure we always set an array;
      setHasNextPage(jobsData.has_next_page);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      throw error;
    }
  }, [apiKey, sidebarPageNum]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      if (isUploading) {
        const message =
          "A file is still uploading. Are you sure you want to leave?";
        e.returnValue = message; // For most browsers
        return e.returnValue; // For older browsers
      }
    };

    if (isUploading) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    

    initializeUserAndFetchJobs();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isUploading, fetchJobs]);

  const activeJobId = location.pathname.split("/results/")[1];

  const handleVideoTypeChange = (type) => {
    setVideoType(type);
  };
  const updateSliderValues = (event) => {
    if (event.target.name === "cut_smoothing") {
      setSliderValue(event.target.value);
    } else if (event.target.name === "min_length") {
      setmin_length_SliderValue(event.target.value);
    } else {
      setbuffer_SliderValue(event.target.value);
    }
  };

  const updateVideoInputValues = (event) => {
    if (event.target.name === "video_link") {
      setVideoLink(event.target.value);
    } else {
      setPrompt(event.target.value);
    }
  };

  const handleSetSpeedValue = (newValue) => {
    setSpeedValue(newValue);
  };

  const handleRenameJob = async (job_id, newTitle) => {
    try {
      const response = await fetch(
        `https://api.usemica.com/rename_job?api_key=${encodeURIComponent(
          apiKey
        )}&job_id=${encodeURIComponent(job_id)}&new_title=${encodeURIComponent(
          newTitle
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) throw new Error("Failed to rename job");
      const updatedJobs = jobs.map((job) =>
        job.job_id === job_id ? { ...job, title: newTitle } : job
      );
      setJobs(updatedJobs);
    } catch (error) {
      console.error("Failed to rename job:", error);
    }
  };

  const handleRenameSplice = async (jobId, splice_id, newTitle) => {
    try {
      const response = await fetch(
        `https://api.usemica.com/rename_splice?api_key=${encodeURIComponent(
          apiKey
        )}&job_id=${encodeURIComponent(jobId)}&splice_id=${encodeURIComponent(
          splice_id
        )}&new_title=${encodeURIComponent(newTitle)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to rename title");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error renaming title:", error);
      throw error;
    }
  };

  const handleLogout = async () => {
    setApiKey("");
    setJobs([]);
    setIsVerified(true);
    try {
      await signOut(auth);
      setIsAuthenticated(false);
      navigate("/login"); // Redirect the user to the login page after logout
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const handleOnSubmit = async () => {
    console.log("type of video in main", videoType);
    const user = auth.currentUser;
    if (!user) {
      // No user is signed in.
      setErrorModalMessage("You must be signed in to submit a job.");
      setErrorModalVisible(true);
      return;
    }

    if (!user.emailVerified) {
      // User's email is not verified.
      setErrorModalMessage(
        "Please verify your email before submitting. If you have verified, refresh the page."
      );
      setErrorModalVisible(true);
      return;
    }

    const formData = new FormData();
    if (isFileUploaded) {
      formData.append("video_file", videoFile);
      formData.append("api_key", apiKey);
      formData.append("prompt", prompt);
      formData.append("cut_smoothing", String(sliderValue));
      formData.append("buffer", String(buffer_sliderValue));
      formData.append("min_length", String(min_length_sliderValue));
      formData.append("video_type", videoType);

      setVideoLink("");
      var element = document.getElementById("yolo");
      element.classList.add("slide");
      element.onanimationend = () => {
        if (speed_value === 0.02) {
          setSpeedValue(0.07);
        } else {
          setSpeedValue(0.02);
        }
      };

      try {
        setIsUploading(true);

        const response = await fetch(
          `https://api.usemica.com/generate_signed_url_for_upload?file_name=${videoFile.name}`
        );
        const data = await response.json();
        if (response.ok) {
          const result = await fetch(data.signed_url, {
            method: "PUT",
            headers: {
              "Content-Type": "video/mp4",
            },
            body: videoFile,
          });

          if (result.ok) {
            const requestUrl = "https://api.usemica.com/process_video";
            const payload = {
              video_link: data.get_signed_url,
              prompt: prompt,
              api_key: apiKey,
              cut_smoothing: sliderValue,
              min_length: min_length_sliderValue,
              buffer: buffer_sliderValue,
              video_type: videoType,
              video_file_name: videoFile.name,
            };

            try {
              const response = await fetch(requestUrl, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              });

              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }

              const data = await response.json();
              jobIdRef.current = data.job_id;

              setJobs((prevJobs) => [
                { job_id: data.job_id, title: "New Job" },
                ...prevJobs,
              ]);

              setIsFileUploaded(false);
              setVideoFile(null);
              setVideoLink("");
              navigate(`/results/${jobIdRef.current}`);
            } catch (error) {
              console.error("Request failed:", error);
            } finally {
              setIsUploading(false);
            }
          } else {
            alert("Upload failed with status: " + result.status);
          }
        } else {
          alert(
            "Failed to get a signed URL. Server responded with: " + data.detail
          );
        }
      } catch (error) {
        console.error("Request failed:", error);
      }
    } else {
      const videoLinkRegex =
        /^https?:\/\/((www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)|vimeo\.com\/|.*\.zoom.us|hello\.chorus\.ai\/listen|storage\.googleapis\.com\/|drive\.google\.com\/file\/|recordings\.meetrecord\.com\/video\/|master\.mux\.com\/).+$/;
      if (!videoLink || !videoLinkRegex.test(videoLink)) {
        setErrorModalMessage(
          "Please provide a valid video link with https://www. from Zoom, Chorus, Youtube, Vimeo, Google Drive, Google Cloud Storage, or MeetRecord. Other videos can be uploaded from your computer using the upload video button."
        );
        setErrorModalVisible(true);
        return;
      } else {
        setErrorModalVisible(false);
      }

      const requestUrl = "https://api.usemica.com/process_video";
      const payload = {
        video_link: videoLink,
        prompt: prompt,
        api_key: apiKey,
        cut_smoothing: sliderValue,
        min_length: min_length_sliderValue,
        buffer: buffer_sliderValue,
        video_type: videoType,
      };

      try {
        const response = await fetch(requestUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        jobIdRef.current = data.job_id;

        setJobs((prevJobs) => [
          { job_id: data.job_id, title: "New Job" },
          ...prevJobs,
        ]);
        setVideoType("other");

        var element = document.getElementById("yolo");
        element.classList.add("slide");
        element.onanimationend = () => {
          navigate(`/results/${jobIdRef.current}`);
          if (speed_value === 0.02) {
            setSpeedValue(0.07);
          } else {
            setSpeedValue(0.02);
          }
        };
      } catch (error) {
        console.error("Request failed:", error);
      }
    }
  };

  const handleOnLogin = async (userId) => {
    const user = auth.currentUser;
    if (!user.emailVerified) {
      setIsVerified(false);
    }

    setIsAuthenticated(true);

    try {
      const apiKeyResponse = await fetch(
        `https://api.usemica.com/get_api_user_key/${encodeURIComponent(userId)}`
      );
      if (!apiKeyResponse.ok) {
        throw new Error("Failed to fetch API key");
      }
      const apiKeyData = await apiKeyResponse.json();
      const userApiKey = apiKeyData.api_key;
      setApiKey(userApiKey);

      await fetchJobs();
    } catch (error) {
      console.error("Error in operations:", error);
    }

    navigate("/submit");
  };

  if (loading) {
    return <div></div>;
  }

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/login" element={<Login onLogin={handleOnLogin} />} />
        <Route path="/signup" element={<Login onLogin={handleOnLogin} />} />
        <Route
          path="/results/:jobId/preview"
          element={
            <Results
              setSpeedValueFunction={handleSetSpeedValue}
              handleRenameSplice={handleRenameSplice}
              isPreview={true}
              userId={userIdToken}
              apiKey={null}
            />
          }
        />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  return (
    <div className="App">
      <div className={`layout`}>
        {/* {!location.pathname.startsWith('/results') && (
          <Sidebar
            jobs={jobs}
            activeJobId={activeJobId}
            handleLogout={handleLogout}
            handleRenameJob={handleRenameJob}
            fetchJobs={fetchJobs}
            sidebarPageNum={sidebarPageNum}
            setSidebarPageNum={setSidebarPageNum}
            hasNextPage={hasNextPage}
          />
        )} */}
        <div className={`content ${isResultsPage ? "results-content-bg" : ""}`}>
        {/* <div className="content-glass"> */}
          <div id="siriwave-wrapper" style={{ display: "none" }}>
            <Siriwave
              theme="ios"
              color="#af00d6"
              speed={speed_value}
              amplitude={1}
              frequency={2}
            />
          </div>
          <Center>
            {isUploading && (
              <div>
                <div
                  id="results-div-2"
                  className="introduction-text fade-in-out"
                >
                  <p>Uploading</p>
                  <CircularProgress 
                    size="40px"
                    isIndeterminate
                    marginTop={5}

                  />
                </div>
                <p id="time-note-2">
                  Please do not close or reload the tab momentarily while your
                  upload is taking place.
                </p>
              </div>
            )}
          </Center>
          <Routes>
            <Route path="/login" element={<Navigate replace to="/submit" />} />
            <Route
              path="/submit"
              element={
                <SubmitVideo
                  handleLogout={handleLogout}
                  handleOnSubmit={handleOnSubmit}
                  setVideoFile={setVideoFile}
                  setVideoLink={setVideoLink}
                  videoLink={videoLink}
                  setIsFileUploaded={setIsFileUploaded}
                  isFileUploaded={isFileUploaded}
                  errorModalVisible={errorModalVisible}
                  errorModalMessage={errorModalMessage}
                  updateVideoInputValues={updateVideoInputValues}
                  updateSlider={updateSliderValues}
                  sliderValue={sliderValue}
                  minLengthValue={min_length_sliderValue}
                  bufferValue={buffer_sliderValue}
                  isVerified={isVerified}
                  videoType={videoType}
                  setVideoType={handleVideoTypeChange}
                  jobs={jobs}
                  activeJobId={activeJobId}
                  handleLogout={handleLogout}
                  handleRenameJob={handleRenameJob}
                  fetchJobs={fetchJobs}
                  sidebarPageNum={sidebarPageNum}
                  setSidebarPageNum={setSidebarPageNum}
                  hasNextPage={hasNextPage}
                />
              }
            />
            <Route
              path="/results/:jobId"
              element={
                <Results
                  setSpeedValueFunction={handleSetSpeedValue}
                  isPreview={false}
                  handleRenameSplice={handleRenameSplice}
                  userId={userIdToken}
                  apiKey={apiKey}
                />
              }
            />
            <Route
              path="/results/:jobId/preview"
              element={
                <Results
                  setSpeedValueFunction={handleSetSpeedValue}
                  isPreview={false}
                  handleRenameSplice={handleRenameSplice}
                  userId={userIdToken}
                  apiKey={null}
                />
              }
            />
            <Route
              path="/test/nonlinear"
              element={
                <VideoEditTest
                  options={videoJsOptions}
                  onReady={handlePlayerReady}
                />
              }
            />
            <Route
              path="/api_key"
              element={
                isAuthenticated ? (
                  <ApiKeyPage apiKey={apiKey} />
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
            <Route path="/" element={<Navigate replace to="/submit" />} />
            <Route path="*" element={<Navigate replace to="/submit" />} />
          </Routes>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default App;
