import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import VideoEditTest from "./VideoEditTest";
import Siriwave from "react-siriwave";
import PersonalizedSection from "./PersonalizedSection";
import responseData from "../mocked/personalized_response.json";
import { useNavigate } from "react-router-dom";
import FloatingSidebar from "./FloatingSidebar";
import {
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Input,
  useToast,
  Button,
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Checkbox,
  PopoverHeader,
  PopoverFooter,
  PopoverCloseButton,
  Text,
  Tag,
  TagLabel,
  Tooltip,
  Textarea,
  useOutsideClick,
  Link,
  Progress,
} from "@chakra-ui/react";

import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faFillDrip,
  faSquareCheck,
  faEnvelope,
  faPen,
  faShare,
  faSave,
  faChevronLeft,
  faAnglesLeft,
  faCircleArrowLeft,
  faSquareCaretLeft,
  faGripLines,
} from "@fortawesome/free-solid-svg-icons";

import { ChromePicker } from "react-color";

import posthog from "posthog-js";
import { useDropzone } from 'react-dropzone';
import { useDrag, useDrop } from 'react-dnd';
import HighlightsSection from "./HighlightsSection";

function Results({
  setSpeedValueFunction,
  isPreview,
  handleRenameSplice,
  userId,
  apiKey,
}) {
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();
  const {
    isOpen: isVideoOpen,
    onOpen: onVideoOpen,
    onClose: onVideoClose,
  } = useDisclosure();
  const {
    isOpen: isColorPopOverOpen,
    onOpen: onisColorPopOverOpen,
    onClose: onColorPopOverClose,
  } = useDisclosure();

  const {
    isOpen: isEmailModalOpen,
    onOpen: onEmailModalOpen,
    onClose: onEmailModalClose,
  } = useDisclosure();
  const {
    isOpen: isHidePopOverOpen,
    onOpen: onisHidePopOverOpen,
    onClose: onHidePopOverClose,
  } = useDisclosure();
  const popoverRef = useRef(null); //this fixes the issue with popover not closing on outside click. Be sure to autofocus false on the chakr
  useOutsideClick({
    ref: popoverRef,
    handler: onHidePopOverClose,
  });

  const colorPopoverRef = useRef(null);
  useOutsideClick({
    ref: colorPopoverRef,
    handler: onColorPopOverClose,
  });

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const colorPickerRef = useRef(null);
  useOutsideClick({
    ref: colorPickerRef,
    handler: () => {
      console.log("yes");
      setIsColorPickerOpen(false);
    },
  });

  const [isSecondColorPickerOpen, setIsSecondColorPickerOpen] = useState(false);
  const secondColorPickerRef = useRef(null);
  useOutsideClick({
    ref: secondColorPickerRef,
    handler: () => {
      setIsSecondColorPickerOpen(false);
    },
  });


  const { jobId } = useParams();
  const shareLink = `https://app.usemica.com/results/${jobId}/preview`; // Construct the share link using jobId
  const toast = useToast(); // Toast notification for copy feedback
  const [hasAccess, setHasAccess] = useState(true);

  const [status, setStatus] = useState("Initializing");
  const [results, setResults] = useState([]);
  const [quip, setQuip] = useState("");
  const [showServerError, setShowServerError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState(null);
  const [fullVideoUrl, setFullVideoUrl] = useState("");
  const [currentTopic, setCurrentTopic] = useState("");
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [editingTitle, setEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [selectedGridItem, setSelectedGridItem] = useState(null); // State to track the selected grid item
  const [animateInputs, setAnimateInputs] = useState(false);


  const wsRef = useRef(null);
  const [nws, setnWs] = useState(null);

  const [personalizedStatus, setPersonalizedStatus] = useState("none");
  const [responseData, setResponseData] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const previousEditableDataRef = useRef(null);
  const previousSelectedBoxesRef = useRef([]);

  const [emailContent, setEmailContent] = useState("");
  const [emailStatus, setEmailStatus] = useState("idle");

  // Helper function to calculate luminosity from a hex color string
  const getLuminosity = (hexColor) => {
    // Remove the hash if it's there
    hexColor = hexColor.replace(/^#/, '');
    
    // Parse the hex string into RGB values
    const r = parseInt(hexColor.substr(0, 2), 16) / 255;
    const g = parseInt(hexColor.substr(2, 2), 16) / 255;
    const b = parseInt(hexColor.substr(4, 2), 16) / 255;
    
    // Calculate relative luminance
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    
    return luminance;
  };

  const updateHiddenSectionsInBackend = async (newVisibility) => {
    try {
      const response = await fetch(
        `https://api.usemica.com/update_hidden_sections`, // Replace with your actual endpoint
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            job_id: jobId, // Replace with your actual job ID variable
            hidden_sections: JSON.stringify(newVisibility),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update hidden sections");
      }
    } catch (error) {
      console.error("Error sending hidden sections update:", error);
    }
  };

  const [headline, setHeadline] = useState(
    "Hey there, here's a recap of your call." // Default headline value
  );


  const [editableData, setEditableData] = useState(null);

  const navigate = useNavigate();

  const handleSectionTextChange = (sectionIndex, itemIndex, field, value) => {
    console.log(sectionIndex, itemIndex, field, value);
    console.log(editableData);
    setEditableData((prevData) => {
      const updatedData = { ...prevData };

      if (field === "sectionTitle") {
        updatedData.sections[sectionIndex].section_title = value;
      } else if (field === "itemTitle") {
        updatedData.sections[sectionIndex].contents[itemIndex].title = value;
      } else if (field === "itemDescription") {
        updatedData.sections[sectionIndex].contents[itemIndex].description = value;
      }

      return updatedData;
    });
  };
//empty 2
  // Handle summary and headline changes separately
  const handleTextChange = (key, value) => {
    setEditableData((prevData) => ({ ...prevData, [key]: value }));
  };

  const [brandColor, setBrandColor] = useState("#fffff");
  const [fontColor, setFontColor] = useState("#FFFFFF");
  const [showBrandColorPicker, setShowBrandColorPicker] = useState(false);
  const [showFontColorPicker, setShowFontColorPicker] = useState(false);

  const handleBrandColorChange = (color) => {
    setBrandColor(color.hex);
    console.log(color.hex);
    // updateColorInBackend(jobId, color.hex, fontColor); // Call the API to update the color
  };

  const handleBrandColorChangePost = (color) => {
    setBrandColor(color.hex);
    console.log(color.hex);
    updateColorInBackend(jobId, color.hex, fontColor); // Call the API to update the color
  };

  const handleFontColorChange = (color) => {
    setFontColor(color.hex);
    // updateColorInBackend(jobId, brandColor, color.hex); // Call the API to update the color
  };

  const handleFontColorChangePost = (color) => {
    setFontColor(color.hex);
    updateColorInBackend(jobId, brandColor, color.hex); // Call the API to update the color
  };

  const updateCSSVariable = (variable, value) => {
    document.documentElement.style.setProperty(variable, value);
  };

  useEffect(() => {
    updateCSSVariable("--brand-color", brandColor);
    updateCSSVariable("--font-color", fontColor);
  }, [brandColor, fontColor]);

  const handleSetDefaultColors = async () => {
    try {
      const url = `https://api.usemica.com/set_default_colors`; // Replace with your actual backend URL
      const payload = {
        api_key: apiKey,
        background_color: brandColor,
        font_color: fontColor,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to update job colors");
      }

      const data = await response.json();
      console.log("Job colors updated successfully:", data);
      toast({
        title: "Colors updated",
        description: "Your default colors have been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error updating job colors:", error);
    }
  };

  const [owner, setOwner] = useState(false);

  const playerRef = React.useRef(null);

  const [segments, setSegments] = useState([]);
  // const segments = [[12.15, 14],[19,26],[40.9327, 45],[52, 59]]

  const [pageviews, setPageviews] = useState("-");


  const handleSetSegments = (topic, cutData) => {
    console.log(cutData);
    setSegments(cutData);
    setCurrentTopic(topic);
    setSelectedGridItem(topic);
    posthog.capture("short-viewed", {
      short_topic: topic,
      job_id: jobId,
    });
    onVideoOpen();
  };

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    playbackRates: [0.5, 1.0, 1.25, 1.5, 1.75, 2.0, 10.0],
    responsive: true,
    playsinline: true,
    fluid: true,
    sources: [
      {
        src: fullVideoUrl,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      // videojs.log('player is waiting');
    });

    player.on("dispose", () => {
      // videojs.log('player will dispose');
    });
  };

  const saveChanges = async () => {
    // To prevent database call when nothing has changed
    const isEditableDataChanged = JSON.stringify(editableData) !== JSON.stringify(previousEditableDataRef.current);
    const isSelectedBoxesChanged = JSON.stringify(selectedBoxes) !== JSON.stringify(previousSelectedBoxesRef.current);

    // Save Text
    let tets = JSON.stringify({
      job_id_fk: jobId,
      custom_json_response: JSON.stringify(editableData),
    });

    console.log(tets);

    try {
      if (isEditableDataChanged) {
        console.log("Editable data changed");
      const response = await fetch(
        `https://api.usemica.com/update_custom_json_response`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            job_id: jobId,
            custom_json_response: JSON.stringify(editableData),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save changes");
      }

      const data = await response.json();
      console.log("Changes saved successfully:", data);
    }

    if (isSelectedBoxesChanged) {
      // Save shorts shared
      console.log("Selected Splices: ", selectedBoxes);

      const url = `https://api.usemica.com/update_sharing_status`;
      const payload = {
        job_id_user: jobId,
        splice_ids: selectedBoxes,
      };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include other headers as required, such as authentication tokens
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to update sharing status");
        }

        const data = await response.json();
        console.log("Sharing status updated: ", data);
        posthog.capture("shorts-shared", {
          job_id: jobId,
          shared_shorts: selectedBoxes,
        });
    }

      toast({
        title: "Changes Saved",
        description: "Your changes have been saved successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      // Update previous data after successful save
  previousEditableDataRef.current = JSON.parse(JSON.stringify(editableData));
      previousSelectedBoxesRef.current = [...selectedBoxes];
    } catch (error) {
      console.error("Error saving changes:", error);
      toast({
        title: "Save Error",
        description:
          "There was an issue saving your changes. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };



  const toggleEditMode = async () => {
    if (editMode) {
      try {
        setIsSavingChanges(true);
        await saveChanges(); // Wait for saveChanges to complete
        setEditMode(false);
      } catch (error) {
        console.error("Error saving changes:", error);
        // Don't toggle edit mode off if there was an error
      } finally {
        setIsSavingChanges(false);
      }
    } else {
      setEditMode(true);
      // Save state of previous data
      previousEditableDataRef.current = JSON.parse(JSON.stringify(editableData));
      previousSelectedBoxesRef.current = [...selectedBoxes];
    }
  };

  const toggleSelectBoxes = (boxId) => {
    setSelectedBoxes((prevSelectedBoxes) => {
      if (prevSelectedBoxes.includes(boxId)) {
        return prevSelectedBoxes.filter((t) => t !== boxId); // Remove topic from the array
      } else {
        return [...prevSelectedBoxes, boxId]; // Add topic to the array
      }
    });
  };

  const updateColorInBackend = async (jobId, backgroundColor, fontColor) => {
    try {
      const url = `https://api.usemica.com/update_job_colors`; // Replace with your actual backend URL
      const payload = {
        job_id: jobId,
        background_color: backgroundColor,
        font_color: fontColor,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to update job colors");
      }

      const data = await response.json();
      console.log("Job colors updated successfully:", data);
    } catch (error) {
      console.error("Error updating job colors:", error);
    }
  };

  const handleCheckboxChange = (e, topic, splice_id) => {
    e.stopPropagation(); // Prevent click from bubbling to the grid item
    toggleSelectBoxes(splice_id); // Toggle selection state
    console.log(selectedBoxes);
  };

  // Function to fetch job settings
  const fetchJobSettings = useCallback(async (jobIdUser) => {
    console.log("COLORSARE BEING FETCHED");
    try {
      const response = await fetch(
        `https://api.usemica.com/job_results_settings/${encodeURIComponent(
          jobIdUser
        )}`
      );
      if (!response.ok) {
        setBrandColor("#ffffff"); // Set background color
        setFontColor("#000000"); // Set font color
        throw new Error("Failed to fetch job settings");
      }
      const data = await response.json();
      setBrandColor(data.background_color || "#ffffff"); // Set background color
      if (isPreview) {
        document.body.style.backgroundColor = data.background_color;
      }
      setFontColor(data.font_color || "#000000"); // Set font color
      // updateCSSVariable("--brand-color", brandColor || "#ffffff");
      console.log("color", data);
    } catch (error) {
      console.error("Error fetching job settings:", error);
    }
  }, []);

  // const fetchHiddenSections = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://api.usemica.com/get_hidden_sections/${jobId}`
  //     );
  //     if (response.ok) {
  //       const data = await response.json();
  //       if (data.hidden_sections) {
  //         console.log("hidden_sections data", data.hidden_sections);
  //         const hiddenSections = JSON.parse(data.hidden_sections);
  //         setSectionsVisibility((prevVisibility) => ({
  //           ...prevVisibility,
  //           ...hiddenSections,
  //         }));
  //       }
  //     } else {
  //       console.error("Failed to fetch hidden sections");
  //       setSectionsVisibility({
  //         pain_points: true,
  //         solutions: true,
  //         pricing_offerings: true,
  //         next_steps: true,
  //         summary: true,
  //         highlights: true, // New state for the highlights section
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching hidden sections:", error);
  //   }
  // };

  const checkJobOnStartup = useCallback(async (element2, element3, sqiggle, element4) => {
    try {
      const response = await fetch(`https://api.usemica.com/check_job_on_startup/${jobId}`);
      if (!response.ok) {
        throw new Error("Failed to check job status");
      }
      const data = await response.json();
      console.log("DATA from checkJobOnStartup", data)
      if (data.job_done) {
        sqiggle.classList.remove("shower");
        element2.classList.add("hider");
        element3.classList.add("hider");
        console.log("JOB IS DONE")
        if(data.data.custom_json_response){
          setEditableData(data.data.custom_json_response);
          setResponseData(data.data.custom_json_response);
          setHeadline(data.data.custom_json_response.headline);
          setPersonalizedStatus("completed");
          setStatus("results");
          sqiggle.classList.add("hider");
          sqiggle.classList.remove("shower");
        }else{
          console.log("JOB IS NOT PERSONALIZED")
          setPersonalizedStatus("none");
        }
        // if(data.data.hidden_sections){
        //   const hiddenSections = JSON.parse(data.data.hidden_sections);
        //   setSectionsVisibility((prevVisibility) => ({
        //     ...prevVisibility,
        //     ...hiddenSections,
        //   }));
        // } else {
        //   // Default case: set all sections to visible
        //   setSectionsVisibility({
        //     pain_points: true,
        //     solutions: true,
        //     pricing_offerings: true,
        //     next_steps: true,
        //     summary: true,
        //     highlights: true,
        //   });
        // }
        
        if (data.data.results) {
          const newResults = Object.entries(data.data.results).map(
            ([topic, { url, cut_data, splice_id, sharing, thumbnail }]) => ({
              topic,
              signedUrl: url,
              cutData: cut_data.map((pair) => pair.map(Number)),
              splice_id,
              sharing,
              thumbnail,
            })
          );

          if (isPreview) {
            const firstSharingResult = newResults.find(
              (result) => result.sharing
            );
            if (firstSharingResult) {
              setCurrentTopic(firstSharingResult.topic);
              setSegments(firstSharingResult.cutData);
            }
          } else {
            setSegments(
              Object.entries(data.data.results)[0][1]["cut_data"].map((pair) =>
                pair.map(Number)
              )
            );
            setCurrentTopic(Object.entries(data.data.results)[0][0]);
          }

          const sharedSpliceIds = newResults
            .filter((result) => result.sharing)
            .map((result) => result.splice_id);
          setSelectedBoxes(sharedSpliceIds);
          setResults(newResults);
        }

        if (data.data.full_video_url) {
          setFullVideoUrl(data.data.full_video_url);
        }

        setStatus("results");
        
      }else{
        console.log("REJECTED")
        setPersonalizedStatus("none");
        setupWebSocket(jobId, element2, element3, sqiggle, element4);
      }
      
        
      //   // Job is completed, populate states with the response data
      //   if (data.data.custom_json_response) {
      //     setEditableData(data.data.custom_json_response);
      //   }
      //   if (data.data.results) {
      //     setResults(data.data.results);
      //   }
      //   if (data.data.hidden_sections) {
      //     setSectionsVisibility((prevVisibility) => ({
      //       ...prevVisibility,
      //       ...JSON.parse(data.data.hidden_sections),
      //     }));
      //   }
      //   if (data.data.full_video_url) {
      //     setFullVideoUrl(data.data.full_video_url);
      //   }
      //   setStatus("completed");
      // } else {
      //   // Job is not completed, set up WebSocket
      //   setupWebSocket(jobId);
      // }
    } catch (error) {
      console.error("Error checking job status:", error);
      // If there's an error, fall back to setting up WebSocket
      setupWebSocket(jobId, element2, element3, sqiggle, element4);
    }
  }, [jobId]);

  

  

  const setupWebSocket = useCallback((jobId, element2, element3, sqiggle, element4) => {
    const ws = new WebSocket(`wss://api.usemica.com/ws/${jobId}`);
    setnWs(ws);
    wsRef.current = ws;
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("data", data);

      if (data.error === true) {
        setShowServerError(true);
        setStatus("error");

        if (data.error_message.includes("age restricted")) {
          setServerErrorMessage(
            "The submitted YouTube video is age restricted. We are working on solving this issue. For now, please try to submit a different video."
          );
        } else if (
          data.error_message.includes("Error downloading video from") &&
          data.error_message.includes("drive.google.com")
        ) {
          setServerErrorMessage(
            `${data.error_message}. You may need to change permission to 'Anyone with a link'.`
          );
        } else if (
          data.error_message.includes("Error downloading video from") &&
          data.error_message.includes("zoom.us")
        ) {
          setServerErrorMessage(
            `${data.error_message}. Please ensure the Zoom recording can be accessed without a passcode`
          );
        } else if (
          data.error_message.includes("Error downloading video from")
        ) {
          setServerErrorMessage(
            `${data.error_message}. Please ensure the link is correct, or alternatively, upload the video directly from your computer.`
          );
        } else if (data.error_message.includes("No shorts generated")) {
          setServerErrorMessage(
            "We couldn't generate any shorts from your video. Please ensure the video is a sales call or similar conversation."
          );
        } else if (data.error_message.includes("No audio found")) {
          setServerErrorMessage(
            "We weren't able to detect any words in your call. Please ensure the video contains audible speech."
          );
        } else {
          setServerErrorMessage(
            "Please check the submitted link or uploaded video and retry your upload."
          );
        }

        element2.classList.add("hider");
        element3.classList.add("hider");
        ws.close();
      } else {
        element4.classList.add("shower")
        // if (data.personalized_info_status === "completed") {
        //   const fetchCustomJsonResponse = async () => {
        //     try {
        //       const response = await fetch(
        //         `https://api.usemica.com/get_custom_json_response/${jobId}`
        //       );
        //       if (response.ok) {
        //         const data = await response.json();
        //         setResponseData(data);
        //         setEditableData(data);
        //         setHeadline(data.headline);
        //         console.log("datadata", data);
        //       } else {
        //         console.error("Failed to fetch custom JSON response");
        //       }
        //     } catch (error) {
        //       console.error("Error fetching custom JSON response:", error);
        //     }
        //   };
        //   fetchCustomJsonResponse();
        //   setPersonalizedStatus("completed");
        // } else {
        //   setPersonalizedStatus("none");
        // }
        setShowServerError(false);
        if (data.status === "pre-processing") {
          element2.classList.remove("hider");
          element3.classList.remove("hider");
          setStatus("Processing");
          setSpeedValueFunction(0.08);
        } else if (data.status === "transcribing") {
          element2.classList.remove("hider");
          element3.classList.remove("hider");
          setStatus("Transcribing");
        } else if (data.status === "analyzing") {
          setStatus("Analyzing");
          setSpeedValueFunction(0.08);
          element2.classList.remove("hider");
          element3.classList.remove("hider");
        } else if (data.status === "started") {
          setStatus("Initializing");
          setSpeedValueFunction(0.08);
          element2.classList.remove("hider");
          element3.classList.remove("hider");
        } else if (data.status === "generating_text_content") {
          setStatus("Generating Page");
          setSpeedValueFunction(0.08);
          element2.classList.remove("hider");
          element3.classList.remove("hider");
        } else if (data.status === "generating_shorts") {
          setStatus("Generating Video Highlights");
          setSpeedValueFunction(0.08);
          element2.classList.remove("hider");
          element3.classList.remove("hider");
        } else if (data.status === "splicing") {
          setStatus("Putting Final Touches");
          setSpeedValueFunction(0.08);
          element2.classList.remove("hider");
          element3.classList.remove("hider");
        } else if (data.status === "completed") {
          console.log("yes");
          setStatus("Completed");
          setSpeedValueFunction(0.02);
          // sqiggle.classList.add("hide");
        } else if (data.status === "results") {
          ws.close();
          console.log("results ready");
          // Fetch the results using GET request
          fetch(`https://api.usemica.com/final_results/${jobId}`)
            .then((response) => response.json())
            .then((data) => {
              setStatus("results");
              setSpeedValueFunction(0.02);
              element2.classList.add("hider");
              element3.classList.add("hider");
              sqiggle.classList.add("hider");
              sqiggle.classList.remove("shower");
              const newResults = Object.entries(data.results).map(
                ([
                  topic,
                  { url, cut_data, splice_id, sharing, thumbnail },
                ]) => ({
                  topic,
                  signedUrl: url,
                  cutData: cut_data.map((pair) => pair.map(Number)),
                  splice_id,
                  sharing,
                  thumbnail,
                })
              );

              if (isPreview) {
                const firstSharingResult = newResults.find(
                  (result) => result.sharing
                );
                if (firstSharingResult) {
                  setCurrentTopic(firstSharingResult.topic);
                  setSegments(firstSharingResult.cutData);
                }
              } else {
                setSegments(
                  Object.entries(data.results)[0][1]["cut_data"].map((pair) =>
                    pair.map(Number)
                  )
                );

                setCurrentTopic(Object.entries(data.results)[0][0]);
              }

              const sharedSpliceIds = newResults
                .filter((result) => result.sharing)
                .map((result) => result.splice_id);
              console.log("SHARED SPLICE IDS", sharedSpliceIds);
              setSelectedBoxes(sharedSpliceIds);
              console.log("NEW RESULTS", Object.entries(data.results));
              setResults(newResults);
              setFullVideoUrl(data.full_video_url);
            })
            .catch((error) => {
              console.error("Error fetching results:", error);
            });
        }
      }
    };
    ws.onerror = (error) => {};

    return ws;
  }, []);

  useEffect(() => {
    if (isPreview) {
      fetch(`https://api.usemica.com/get_job_sharing_status/${jobId}`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.sharing) {
            setHasAccess(true);
          } else {
            setHasAccess(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching sharing status:", error);
          setHasAccess(false); // Assume no access on error
        });
    } else {
      fetch(`https://api.usemica.com/verify_user_owner/${userId}/${jobId}`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.is_owner) {
            setOwner(true);
          } else {
            setOwner(false);
          }
        })
        .catch((error) => {
          console.error("Error verifying user ownership:", error);
          setOwner(false); // Assume not owner on error
        });
    }
    setAnimateInputs(true); 
    fetchJobSettings(jobId);
    // fetchHiddenSections();


    
    var element2 = document.getElementById("results-div");
    var element3 = document.getElementById("time-note");
    var sqiggle = document.getElementById("siriwave-wrapper");
    var element4 = document.getElementById("results-div-container");

    
    sqiggle.classList.add("shower");
    element2.classList.remove("slide2");
    element2.classList.add("slide2");
    element2.onanimationend = () => {
      element2.classList.remove("slide2");
      element2.classList.add("fade-in-out");
    };

    checkJobOnStartup(element2, element3, sqiggle, element4);

    // const ws = setupWebSocket(jobId, element2, element3, sqiggle);

    return () => {
      if (!isPreview) {
        sqiggle.classList.remove("hide");
      }
      sqiggle.classList.remove("shower");
      setBrandColor("#ffffff"); // Set background color
      setFontColor("#000000"); // Set font color

      setSpeedValueFunction(0.02);

      element2.classList.remove("slide2");
      element4.classList.remove("shower");
      // ws.close();
      console.log("nws, CLOSED", nws);
      if(nws){
        console.log("nws, CLOSING", nws);
      nws.close();
      wsRef.current.close();
      }
    };
 }, [jobId, isPreview, personalizedStatus]);
   
  
  useEffect(() => {
    console.log("Started pageview check")
    if (!isPreview && owner) {
      console.log("Fetching pageviews")
      fetchPageviews();
    }
  }, [jobId, owner, isPreview]);

  const fetchPageviews = async () => {
    try {
      const response = await fetch(
        `https://api.usemica.com/pageviews/${jobId}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch pageviews");
      }

      const data = await response.json();
      if (data.pageviews !== undefined) {
        setPageviews(data.pageviews.toString());
      }
    } catch (error) {
      console.error("Error fetching pageviews:", error);
    }
  };

  const calculateTotalDuration = (segments) => {
    return segments.reduce((total, [start, end]) => total + (end - start), 0);
  };

  const handleShareLinkCopy = async () => {
    const linkHTML = `<a href="${shareLink}">${shareLink}</a>`;
  
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([linkHTML], { type: 'text/html' }),
          'text/plain': new Blob([shareLink], { type: 'text/plain' })
        })
      ]);
      console.log('Link copied to clipboard');
    toast({
      title: "Link Copied.",
      description: "You've copied the share link to your clipboard.",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast({
        title: "Error Copying Link",
        description: "Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const saveTitle = useCallback(
    async (splice_id) => {
      setEditingTitle(false);
      if (newTitle.trim() === "") {
        return;
      }

      const oldTitle = currentTopic;
      try {
        setResults((prevResults) =>
          prevResults.map((result) =>
            result.splice_id === splice_id
              ? { ...result, topic: newTitle }
              : result
          )
        );
        setCurrentTopic(newTitle);
        const data = await handleRenameSplice(jobId, splice_id, newTitle);
        posthog.capture("short-renamed", {
          job_id: jobId,
          splice_id: splice_id,
          old_title: oldTitle,
          new_title: newTitle,
        });
        setNewTitle("");
      } catch (error) {
        console.error("Error renaming title:", error);
        setResults((prevResults) =>
          prevResults.map((result) =>
            result.splice_id === splice_id
              ? { ...result, topic: oldTitle }
              : result
          )
        );
        setCurrentTopic(oldTitle);
      }
    },
    [jobId, newTitle, handleRenameSplice, currentTopic]
  );

  const handleShare = () => {
    if (editMode) {
      toast({
        title: "Unsaved Changes",
        description: "Please save your changes before sharing.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } else {
      onShareOpen();
      posthog.capture("page-shared", {
        job_id: jobId,
      });
    }
  };

  const handleGenerateEmail = async () => {
    onEmailModalOpen();
    setEmailStatus("loading");
    posthog.capture("generating_email", {
      job_id: jobId,
    });

    try {
      const response = await fetch(`https://api.usemica.com/get_followup_email/${jobId}?api_key=${apiKey}`);
      
      if (response.status === 200) {
        const data = await response.json();
        setEmailContent(data.email);
        setEmailStatus("success");
      } else if (response.status === 404) {
        // Open WebSocket connection
        const ws = new WebSocket(`wss://api.usemica.com/ws/generate_followup_email/${jobId}?api_key=${apiKey}`);
        
        ws.onmessage = async (event) => {
          const data = JSON.parse(event.data);
          
          if (data.status === "started") {
            setEmailStatus("generating");
          } else if (data.status === "error") {
            setEmailStatus("error");
          } else if (data.status === "completed") {
            ws.close();
            const getResponse = await fetch(`https://api.usemica.com/get_followup_email/${jobId}?api_key=${apiKey}`);
            if (getResponse.ok) {
              const emailData = await getResponse.json();
              setEmailContent(emailData.email);
              setEmailStatus("success");
            } else {
              setEmailStatus("error");
            }
          }
        };

        ws.onerror = () => {
          setEmailStatus("error");
        };
      } else {
        setEmailStatus("error");
      }
    } catch (error) {
      console.error("Error generating email:", error);
      setEmailStatus("error");
    }
  };

  const handleEmailCopy = async ( emailContent ) => {
    const linkRegex = /\b(?:https?:\/\/|www\.)\S+\b(?<!\.)(?=[^.]*(?:\.\s|\s|$))/g;
    const emailHTML = emailContent.replace(linkRegex, match => `<a href="${match}">${match}</a>`).replace(/\n/g, '<br>');

    try {
      // When pasting into rich text editors (like Gmail, Word, or other WYSIWYG editors), the HTML content will be interpreted correctly, preserving the formatting and clickable hyperlink.
      // When pasting into plain text editors (like Notepad or a plain text email), the plain text version ensures that the URL is still available, even though it won't be clickable.
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([emailHTML], { type: 'text/html' }),
          'text/plain': new Blob([emailContent], { type: 'text/plain' })
        })
      ]);
      posthog.capture("email-copied", {
        job_id: jobId,
      });
    toast({
      title: "Email Copied.",
      description: "You've copied the email to your clipboard.",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast({
        title: "Error Copying Email",
        description: "Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result;
      setEditableData((prevData) => ({
        ...prevData,
        logo_base64: base64,
      }));
    };
    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: {'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif', '.webp']} });


  const ItemType = "section";
  const DraggableSection = ({ section, index, moveSection, toggleSectionVisibility }) => {
    const ref = useRef(null);
  
    const [{ handlerId }, drop] = useDrop({
      accept: ItemType,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        console.log("dragIndex", dragIndex)
        console.log("hoverIndex", hoverIndex)
  
        if (dragIndex === hoverIndex) {
          return;
        }
  
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        
        // Only perform the move when the mouse has crossed half of the items height
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
  
        moveSection(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    });
  
    const [{ isDragging }, drag] = useDrag({
      type: ItemType,
      item: { type: ItemType, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (monitor.didDrop()) {  
          (async() => {await saveChanges();})();
        }
      },
    });
  
    drag(drop(ref));
  
    return (
      <Box
        ref={ref}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
        zIndex={9999}
        bg="#3F3F3F"
        borderRadius="md"
        padding="10px"
        opacity={isDragging ? 0.5 : 1}
      >
        <Checkbox
          isChecked={!section.hidden}
          onChange={() => toggleSectionVisibility(index)}
          colorScheme="teal"
          mr={3}
        >
          {section.section_title}
        </Checkbox>
        <Box cursor="grab">
          <FontAwesomeIcon icon={faGripLines} />
        </Box>
      </Box>
    );
  };

  const DraggableSectionsList = ({ sections }) => {
    const moveSection = (dragIndex, hoverIndex) => {
      const updatedSections = [...sections];

      // Move the section
      const [removed] = updatedSections.splice(dragIndex, 1);
      updatedSections.splice(hoverIndex, 0, removed);

      // Update the state with the new order
      setEditableData((prevData) => ({
        ...prevData,
        sections: updatedSections,
      }));
    };

    const toggleSectionVisibility = async (sectionIndex) => {
        setEditableData((prevData) => {
        const updatedSections = [...prevData.sections];
        if (sectionIndex !== -1) {
          updatedSections[sectionIndex].hidden = !updatedSections[sectionIndex].hidden;
        }
          return { ...prevData, sections: updatedSections };
        });
    };

    return (
      <div>
        {sections.map((section, index) => (
          <DraggableSection
            key={section.section_title}
            index={index}
            section={section}
            moveSection={moveSection}
            toggleSectionVisibility={toggleSectionVisibility}
          />
        ))}
      </div>
    );
  };

  if (!hasAccess) {
    return (
      <Center height="100vh">
        <p>This resource is not shared by the owner.</p>
      </Center>
    );
  }

  return (
    <div>
      {responseData && personalizedStatus === "completed" && (
        <FloatingSidebar sections={editableData.sections} editableData={editableData}
        editMode={editMode}
        setEditMode={setEditMode}
        status={status}
        personalizedStatus={personalizedStatus}
        responseData={responseData}
        results={results}
        selectedBoxes={selectedBoxes}
        handleCheckboxChange={handleCheckboxChange}
        handleSetSegments={handleSetSegments}
        calculateTotalDuration={calculateTotalDuration}
        animateInputs={animateInputs}
        handleSectionTextChange={handleSectionTextChange}
        />
      )}
      {/* {console.log('EDITABLE DATA', editableData.sections)} */}
      <div className="result-top-bar">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            icon={faSquareCaretLeft}
            onClick={() => navigate('/submit')}
            style={{
              cursor: 'pointer',
              marginLeft: '20px',
              color: getLuminosity(brandColor) > 0.5 ? 'black' : 'white',
              fontSize: '20px'
            }}
          />
          <div className="result-logo">
            <img
              src={`${process.env.PUBLIC_URL}/mainlogo.png`}
              alt="Logo"
              style={{ height: "30px" }}
            />
          </div>
        </div>

        {!isPreview && owner && (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Pageviews */}
            <Tooltip label="Pageviews">
              <Tag
                size="lg"
                marginRight={"5px"}
                backgroundColor={brandColor}
              >
                <TagLabel color={getLuminosity(brandColor) > 0.5 ? 'black' : 'white'}>
                  {" "}
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{ marginRight: "5px" }}
                  />{" "}
                  {pageviews}
                </TagLabel>
              </Tag>
            </Tooltip>

            {/* Hide and reorder sections - `editableData &&` to make sure this doesn't render before data is loaded */}
            {editableData && (
              <Box
                textAlign="right"
                style={{
                  position: "relative",
                  zIndex: 9999,
                  padding: "10px",
                }}
                border={"none"}
                ref={popoverRef}
              >
                <Popover
                  autoFocus={false}
                  isOpen={isHidePopOverOpen}
                  onClose={onHidePopOverClose}
                >
                  <Tooltip label="Choose sections">
                    <span>
                      <PopoverTrigger>
                        <FontAwesomeIcon
                          icon={faSquareCheck}
                          color={getLuminosity(brandColor) > 0.5 ? 'black' : 'white'}
                          style={{ marginRight: "20px", cursor: "pointer" }}
                          onClick={onisHidePopOverOpen}
                        />
                      </PopoverTrigger>
                    </span>
                  </Tooltip>

                  <PopoverContent
                    zIndex={9999}
                    bg="#2E2E2E"
                    color="white"
                    borderRadius="10px"
                    width="300px"
                    padding="15px"
                    paddingBottom="10px"
                    border="none"
                    fontFamily="CircularXX, Inter, sans-serif"
                  >
                    <PopoverCloseButton
                      color="white"
                      border="none"
                      cursor="pointer"
                    />
                    <Text
                      border="0"
                      textAlign={"left"}
                      marginLeft={"15px"}
                      marginBottom={"0"}
                      fontSize={"15px"}
                      fontWeight={"600"}
                    >
                      Manage Sections
                    </Text>
                    <Text
                      fontSize="sm"
                      color="gray.400"
                      textAlign={"left"}
                      marginTop={"5px"}
                      marginLeft={"15px"}
                    >
                      Hide/show and reorder sections
                    </Text>
                    <PopoverBody zIndex={9999} padding="16px">
                      <DraggableSectionsList sections={editableData.sections} />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            )}

            {/* Color picker */}
            <Box
              style={{ position: "relative", zIndex: 9999 }}
              border={"none"}
              ref={colorPopoverRef}
            >
              <Popover
                isOpen={isColorPopOverOpen}
                onClose={onColorPopOverClose}
                placement="top-end"
                closeOnBlur={true}
                closeOnEsc={true}
              >
                <Tooltip label="Choose theme">
                  <span>
                    <PopoverTrigger>
                      <FontAwesomeIcon
                        icon={faFillDrip}
                        color={getLuminosity(brandColor) > 0.5 ? 'black' : 'white'}
                        style={{ marginRight: "25px", cursor: "pointer" }}
                        onClick={onisColorPopOverOpen}
                      />
                    </PopoverTrigger>
                  </span>
                </Tooltip>

                <PopoverContent
                  bg="#2E2E2E"
                  color="white"
                  borderRadius="lg"
                  width="300px"
                  padding="15px"
                  paddingBottom="20px"
                  zIndex="popover"
                  border={"none"}
                  textAlign={"left"}
                  fontFamily="CircularXX"
                >
                  <PopoverCloseButton
                    color="white"
                    border={"none"}
                    cursor="pointer"
                    paddingTop="15px"
                  />
                  <PopoverBody border={"none"}>
                    <Box mb={4}>
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        mb={1}
                        marginTop={0}
                        marginBottom={0}
                      >
                        Color Settings
                      </Text>
                      <Text
                        fontSize="sm"
                        color="gray.400"
                        textAlign={"left"}
                        marginTop={"5px"}
                      >
                        Set your background and font colors
                      </Text>
                      <Button
                        size="sm"
                        border="none"
                        onClick={handleSetDefaultColors}
                        color="black"
                      >
                        Set Default
                      </Button>
                    </Box>

                    <Box mb={4}>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        mb={2}
                        marginTop={"25px"}
                      >
                        Background color
                      </Text>
                      <Box
                        bg="#3F3F3F"
                        borderRadius="md"
                        padding="10px"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={"15px"}
                        cursor={"pointer"}
                        onClick={() => {
                          setShowBrandColorPicker(!showBrandColorPicker);
                          setIsColorPickerOpen(true);
                        }}
                      >
                        <Input
                          variant="unstyled"
                          value={brandColor}
                          readOnly
                          fontFamily={"CircularXX"}
                          border={"none"}
                          color="gray.400"
                          fontSize="sm"
                          width="auto"
                        />
                        <Box
                          bg={brandColor}
                          borderRadius="full"
                          boxSize="20px"
                        />
                      </Box>

                      {isColorPickerOpen && (
                        <Box
                          ref={colorPickerRef}
                          position="absolute"
                          zIndex={9999}
                          mt={2}
                          bg="black"
                          p={2}
                          borderRadius="md"
                          style={{
                            backgroundColor: "rgba(0, 0, 0,.4)",
                            backdropFilter: "blur(4px)",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              onClick={() => setShowBrandColorPicker(false)}
                              style={{
                                color: "white",
                                border: "none",
                                background: "transparent",
                                cursor: "pointer",
                                fontSize: "16px",
                                marginBottom: "5px",
                              }}
                            >
                              ✕
                            </button>
                          </div>
                          <ChromePicker
                            color={brandColor}
                            onChange={handleBrandColorChange}
                            onChangeComplete={handleBrandColorChangePost}
                            disableAlpha
                          />
                        </Box>
                      )}
                    </Box>

                    {/* Additional Font Color Section */}
                    <Box>
                      <Text fontSize="sm" fontWeight="semibold" mb={2}>
                        Font color
                      </Text>
                      <Box
                        bg="#3F3F3F"
                        borderRadius="md"
                        border={"none"}
                        padding="10px"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={"1.25rem"}
                        cursor={"pointer"}
                        onClick={() => setIsSecondColorPickerOpen(true)}
                      >
                        <Input
                          variant="unstyled"
                          value={fontColor}
                          readOnly
                          border={"none"}
                          fontFamily={"CircularXX"}
                          color="gray.400"
                          fontSize="sm"
                          width="auto"
                        />
                        <Box
                          bg={fontColor}
                          borderRadius="full"
                          boxSize="20px"
                        />
                      </Box>
                      {isSecondColorPickerOpen && (
                        <Box
                          position="absolute"
                          zIndex={9999}
                          mt={2}
                          bg="black"
                          p={2}
                          borderRadius="md"
                          style={{
                            backgroundColor: "rgba(0, 0, 0,.4)",
                            backdropFilter: "blur(4px)",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              onClick={() => setShowFontColorPicker(false)}
                              style={{
                                color: "white",
                                border: "none",
                                background: "transparent",
                                cursor: "pointer",
                                fontSize: "16px",
                                marginBottom: "5px",
                              }}
                            >
                              ✕
                            </button>
                          </div>
                          <ChromePicker
                            color={fontColor}
                            onChange={handleFontColorChange}
                            onChangeComplete={handleFontColorChangePost}
                            disableAlpha
                          />
                        </Box>
                      )}
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
            {/* Edit Button */}
            {isSavingChanges ? (
              <Button
                color={getLuminosity(brandColor) > 0.5 ? 'white' : 'black'}
                backgroundColor={getLuminosity(brandColor) > 0.5 ? 'black' : 'white'}
                opacity={0.9}
                className="edit-text-button"
                onClick={() => {}}
                isLoading
                loadingText="Saving..."
                _hover={{ bg: fontColor, color: brandColor }}
              />
            ) : (
              editMode ? (
                <Button
                  color="white"
                  backgroundColor="blue.500"
                  className="edit-text-button"
                  onClick={toggleEditMode}
                  size="sm"
                  _hover={{ bg: "#515151" }}
                  leftIcon={<FontAwesomeIcon icon={faSave} />}
                  key="saveButton"
                >
                  Save
                </Button>
              ) : (
                <FontAwesomeIcon
                  icon={faPen}
                  color={getLuminosity(brandColor) > 0.5 ? 'black' : 'white'}
                  onClick={toggleEditMode}
                  style={{
                    cursor: 'pointer',
                    fontSize: '1rem',
                    marginRight: '20px'
                  }}
                />
              )
            )}
            {/* Cancel changes button */}
            {editMode && (
              <Button
                colorScheme="red"
                variant="outline"
                className="edit-text-button"
                size="sm"
                onClick={() => {
                  if (window.confirm("Are you sure you want to cancel changes? All unsaved changes will be lost.")) {
                    setEditableData(previousEditableDataRef.current);
                    setSelectedBoxes(previousSelectedBoxesRef.current);
                    setEditMode(false);
                  }
                }}
              >
                Cancel
              </Button>
            )}
            {/* Generate Email button */}
            <Button
              className="generate-email-button"
              color={getLuminosity(brandColor) > 0.5 ? 'white' : 'black'}
              backgroundColor={getLuminosity(brandColor) > 0.5 ? 'black' : 'white'}
              onClick={handleGenerateEmail}
              leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
              _hover={{ bg: "#515151" }}
              size="sm"
            >
              Generate Email
            </Button>
            {/* Share button */}
            <Button
              color={getLuminosity(brandColor) > 0.5 ? 'white' : 'black'}
              backgroundColor={getLuminosity(brandColor) > 0.5 ? 'black' : 'white'}
              className="share-button"
              onClick={handleShare}
              _hover={{ bg: "#515151" }}
              marginRight={"10px"}
              size="sm"
            >
              Share
            </Button>
          </div>
        )}
      </div>
      
      <div
        className="results-content"
        style={{
          // width: "70%",
          // margin: "auto",
          position: "relative",
          // overflowX: "hidden",
          // padding: "20px",
        }}
      >
        <div style={{width: "80%", margin: "auto"}}>
        <Center>
          {isPreview && (
            <div id="siriwave-wrapper">
              <Siriwave
                theme="ios"
                color="#af00d6"
                speed={0.07}
                amplitude={1}
                frequency={2}
              />
            </div>
          )}
        </Center>

        {showServerError && (
          <Center>
            <Alert status="error" borderRadius={"10px"} fontFamily={"Lato"}>
              <AlertIcon />
              <AlertTitle textAlign="left">
                There was an error processing your request.
              </AlertTitle>
              <AlertDescription textAlign="left">
                {serverErrorMessage}
              </AlertDescription>
            </Alert>
          </Center>
        )}

        <div id="results-div-container">
          <Center>
            <div id="results-div" className="introduction-text">
              <p>{status}</p>
            </div>
          </Center>

          {/* Progress bar */}
          {status !== 'Completed' && status !== 'results' && (
            <Progress 
              colorScheme='purple' 
              size='lg' 
              value={
                status === 'Initializing' ? 5 :
                status === 'Processing' ? 15 :
                status === 'Transcribing' ? 30 :
                status === 'Analyzing' ? 50 :
                status === 'Generating Page' ? 60 :
                status === 'Generating Video Highlights' ? 80 :
                status === 'Putting Final Touches' ? 95 : null
              } 
              hasStripe 
              isAnimated 
              marginTop={5} 
            />
          )}
          <p id="time-note">
            Processing in progress, usually taking 5-10 minutes. It's worth the wait!
            Feel free to click out of the tab; your video will keep processing in
            the background. You may see other information load before the video
            highlights are completed.{" "}
          </p>
        </div>
        

        {status === "results" && (
          <div>
            <div className={`header-text`}>
              {/* Editable logo */}
              {editMode ? (
                editableData.logo_base64 ? (
                  <div className="image-container">
                    <img src={editableData.logo_base64} alt="Logo" className="logo" style={{ maxWidth: "90%", height: "auto" }} />
                    <IconButton 
                      aria-label="Remove image" 
                      icon={<DeleteIcon />} 
                      colorScheme="red" 
                      border="none" 
                      marginLeft={"0.5rem"} 
                      onClick={() => setEditableData(prevData => ({ ...prevData, logo_base64: null }))} 
                    />
                  </div>
                ) : (
                  <div {...getRootProps()} className="dropzone" style={{ width: "20%" }}>
                    <input {...getInputProps()} />
                    <p>Add an image</p>
                  </div>
                )
              ) : (
                editableData.logo_base64 && <img src={editableData.logo_base64} alt="Logo" className="logo" style={{ maxWidth: "90%", height: "auto" }} />
              )}

              {/* Editable headline */}
              {editMode ? (
                <Textarea
                  className={"editable"}
                  value={editableData.headline}
                  onChange={(e) => handleTextChange("headline", e.target.value)}
                  fontSize="1.5rem"
                  fontWeight="600"
                  fontFamily="CircularXX, Inter, sans-serif"
                  color="var(--font-color)"
                  variant="flushed"
                  marginBottom="1.25rem"
                  borderRadius={"5px"}
                  width={"90%"}
                  border="1px solid grey"
                  padding={"10px"}
                  whiteSpace="pre-wrap"
                  resize="none"
                  overflow="hidden"
                />
              ) : (
                <p>
                  {personalizedStatus === "completed" && responseData
                    ? editableData.headline
                    : "Hey there, here's a recap of your call."}
                </p>
              )}
            </div>

            <hr className="divider-no-animate" />

            {responseData && personalizedStatus === "completed" && (
              <>
                {editableData.sections.map((section, index) => {
                  console.log("sections", editableData);
                  console.log('personalizedStatus', personalizedStatus);
                  if (!section.hidden) {
                    if (section.section_type === "videos" && results) {
                      return (
                        <HighlightsSection
                          key={index}
                          results={results}
                          selectedBoxes={selectedBoxes}
                          editMode={editMode}
                          handleCheckboxChange={handleCheckboxChange}
                          handleSetSegments={handleSetSegments}
                          calculateTotalDuration={calculateTotalDuration}
                          animateInputs={animateInputs}
                          index={index}
                        />
                      );
                    } else {
                      return (
                        <PersonalizedSection
                          key={index}
                          sectionIndex={index}
                          sectionTitle={section["section_title"]}
                          sectionData={section["contents"]}
                          isEditing={editMode}
                          handleSectionTextChange={handleSectionTextChange}
                          index={index}
                        />
                      );
                    }
                  }
                  return null;
                })}
              </>
            )}

            <Modal
              isOpen={isVideoOpen}
              onClose={() => {
                onVideoClose();
                posthog.capture("short-closed", {
                  job_id: jobId,
                  splice_id: results.find((result) => result.topic === currentTopic)
                    ?.splice_id,
                  short_topic: currentTopic,
                });
              }}
            >
              <ModalOverlay />
              <ModalContent maxH="1200px" maxW="1200px">
                <ModalCloseButton border={"none"} cursor={"pointer"} />
                <ModalBody>
                  <VideoEditTest
                    currentTopic={currentTopic}
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                    segments={segments}
                    isPreview={isPreview}
                    editingTitle={editingTitle}
                    setEditingTitle={setEditingTitle}
                    newTitle={newTitle}
                    setNewTitle={setNewTitle}
                    saveTitle={saveTitle}
                    spliceId={
                      results.find((result) => result.topic === currentTopic)
                        ?.splice_id
                    }
                  />
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal isOpen={isShareOpen} onClose={onShareClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Here is your share link.</ModalHeader>
                <ModalCloseButton border="none" cursor="pointer" />
                <ModalBody>
                  <p>This page can be viewed by anyone with the link.</p>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderWidth="1px"
                    borderRadius="lg"
                    bg="gray.100"
                  >
                    <Input
                      value={shareLink}
                      isReadOnly
                      size="md"
                      pl="1.5"
                      pr="1.5"
                      variant="filled"
                      overflowX="auto"
                      whiteSpace="nowrap"
                      fontWeight="bold"
                    />
                    <IconButton
                      aria-label="Copy link"
                      icon={<CopyIcon />}
                      onClick={handleShareLinkCopy}
                      mr="1"
                      size="sm"
                      cursor="pointer"
                      colorScheme="purple"
                      border="none"
                    >
                      Copy
                    </IconButton>
                  </Box>
                </ModalBody>
                <ModalFooter />
              </ModalContent>
            </Modal>

            {/* Follow-up Email Modal */}
            <Modal isOpen={isEmailModalOpen} onClose={onEmailModalClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Follow-up Email</ModalHeader>
                {emailStatus === "success" && (
                  <ModalBody mb={2}>
                    Fill in your prospect's name and your signature. We've automatically filled in the rest and included the shareable link to this page
                  </ModalBody>
                )}
                <ModalCloseButton border="none" cursor="pointer" />
                <ModalBody>
                  {emailStatus === "loading" && <p>Loading...</p>}
                  {emailStatus === "generating" && <p>Generating email...</p>}
                  {emailStatus === "error" && (
                    <>
                      <p>Error generating follow-up email.</p>
                      <Button onClick={handleGenerateEmail} border="none" colorScheme="purple">Retry</Button>
                    </>
                  )}
                  {emailStatus === "success" && (
                    <>
                      <Box border="1px solid grey" borderRadius="5px" padding="10px" width="90%">
                        <Text overflow="auto" whiteSpace="pre-wrap" fontFamily="CircularXX" fontSize="md">
                          {emailContent.split(/\b(https?:\/\/\S+)\b/).map((part, index) => 
                            part.startsWith('http') ? (
                              <Link key={index} href={part} isExternal color="blue.500" textDecoration="underline">
                                {part.trim().replace(/\.$/, '')}
                              </Link>
                            ) : (
                              part
                            )
                          )}
                        </Text>
                      </Box>
                      <Button
                        mt={4}
                        border="none"
                        colorScheme="purple"
                        onClick={() => {
                          handleEmailCopy(emailContent);
                        }}
                      >
                        Copy Email
                      </Button>
                    </>
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>
          </div>
        )}
      </div>
    </div>
    </div>
  );
}

export default Results;
