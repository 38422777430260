import React, { useState, useRef, useCallback } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { Input, InputGroup, InputRightAddon, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faCheck, faDownload } from "@fortawesome/free-solid-svg-icons";
import posthog from "posthog-js";

export const VideoEditTest = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const currentTimeRef = useRef(0);
  const titleInputRef = useRef(null);
  const [currentTimeState, setCurrentTimeState] = useState(0);
  // const [segments, setSegments] = useState([
  //   [12.15, 14],
  //   [19,26], // First segment from 10s to 15s
  //   [40.9327, 45],
  //   [52, 59] // Second segment from 30s to 35s
  //   // Add more segments as needed
  // ]);

  const segments = props.segments;

  const { options, onReady } = props;

  const [playbackRange, setPlaybackRange] = useState({ start: 30, end: 35 }); // Default range
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);

  const currentSegmentRef = useRef(0);

  const [playerInitialized, setPlayerInitialized] = useState(false);

  React.useEffect(() => {
    // console.log('restarted', segments)
    currentSegmentRef.current = 0;

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // console.log('reading up')
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        player.currentTime(props.segments[currentSegmentIndex][0]);
        // videojs.log('player is ready');
        onReady && onReady(player);
        // console.log('yolo')
        // player.currentTime(playbackRange.start);
        // player.play();
      }));
      setPlayerInitialized(true);

      const BUFFER = 0.2;
      player.on("timeupdate", () => {
        const currentTime = player.currentTime();
        // console.log('CURRENT TIME ACTUAL', currentTime)
        // Update state with the current time for the seek bar
        const currentSegment = props.segments[currentSegmentRef.current];

        // Check if the current time has passed the end of the current segment
        if (currentTime >= currentSegment[1] - BUFFER) {
          // console.log('yes')
          if (currentSegmentRef.current < props.segments.length - 1) {
            // Move to the next segment if there is one
            currentSegmentRef.current += 1;
            player.currentTime(props.segments[currentSegmentRef.current][0]); // Jump to the start of the next segment
          } else {
            // Optionally loop back to the first segment or stop the playback
            currentSegmentRef.current = 0; // Reset to the first segment
            player.currentTime(props.segments[0][0]); // Jump to the start of the first segment
            player.pause(); // You could also loop by not pausing
          }
        }
        setCurrentTimeState(currentTime);
        // currentTimeRef.current = currentTime
      });
    } else {
      // console.log('hello2')

      const player = playerRef.current;
      player.currentTime(props.segments[currentSegmentIndex][0]);
      // player.currentTime(playbackRange.start)
      //   player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [props.segments]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef, props.segments]);

  const totalSegmentsDuration = props.segments.reduce(
    (acc, [start, end]) => acc + (end - start),
    0
  );

  // Maps seek bar value to actual video time
  const mapSeekBarToVideoTime = (seekBarValue) => {
    let timeAccumulated = 0;
    for (let [start, end] of props.segments) {
      let segmentDuration = end - start;
      if (seekBarValue <= timeAccumulated + segmentDuration) {
        return start + (seekBarValue - timeAccumulated);
      }
      timeAccumulated += segmentDuration;
    }
    return 0; // default to the start of the first segment if out of bounds
  };

  // Maps video time to seek bar value
  const mapVideoTimeToSeekBar = (videoTime) => {
    // console.log('TimeTimeaTime',videoTime, totalSegmentsDuration)

    let seekBarValue = 0;
    for (let [start, end] of props.segments) {
      // console.log(start, end)
      // console.log('Index Ref:', currentSegmentRef.current)
      if (videoTime >= start && videoTime <= end) {
        // console.log('calculated', seekBarValue + (videoTime - start))
        return seekBarValue + (videoTime - start);
      }
      seekBarValue += end - start;
    }
    return 0; // default to the start of the first segment if out of bounds
  };

  const remainingTime =
    totalSegmentsDuration - mapVideoTimeToSeekBar(currentTimeState);

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toISOString().slice(11, 19); // Extracts time as HH:MM:SS from the ISO string
  };

  const handleSeek = (event) => {
    const seekBarValue = parseFloat(event.target.value);
    const videoTime = mapSeekBarToVideoTime(seekBarValue);
    playerRef.current.currentTime(videoTime);
    posthog.capture("short-seeked", {
      job_id: props.jobId,
      splice_id: props.spliceId,
      seek_time: videoTime,
    });

    // Update currentSegmentRef to reflect the new time
    let cumulativeDuration = 0;
    for (let i = 0; i < props.segments.length; i++) {
      const [start, end] = props.segments[i];
      if (videoTime >= start && videoTime <= end) {
        currentSegmentRef.current = i;
        break; // Exit the loop as we found the segment
      }
      cumulativeDuration += end - start;
      if (cumulativeDuration > seekBarValue) {
        currentSegmentRef.current = i;
        break; // If the cumulative duration exceeds the seek bar value, set the segment
      }
    }
  };

  const handleEditClick = useCallback(() => {
    props.setEditingTitle(true);
    props.setNewTitle(props.currentTopic);
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [props]);

  return (
    <div className="video-player-wrapper">
      <div className="title-button-container">
        {props.editingTitle ? (
          <InputGroup size="md" mr="5">
            <Input
              variant="filled"
              fontWeight="bold"
              ref={titleInputRef}
              type="text"
              value={props.newTitle}
              onChange={(e) => {
                props.setNewTitle(e.target.value);
              }}
              onBlur={() => props.saveTitle(props.spliceId)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.saveTitle(props.spliceId);
                }
              }}
              autoFocus
            />
            <InputRightAddon
              onClick={() => props.saveTitle(props.spliceId)}
              cursor="pointer"
              height="inherit"
            >
              <FontAwesomeIcon icon={faCheck} title="Save title" />
            </InputRightAddon>
          </InputGroup>
        ) : (
          <div className="current-title">
            {props.currentTopic}
            {!props.isPreview && (
              <FontAwesomeIcon
                icon={faPen}
                className="title-edit-icon"
                onClick={handleEditClick}
                title="Rename short"
              />
            )}
          </div>
        )}
      </div>

      <div data-vjs-player>
        <div ref={videoRef} />
      </div>

      <input
        className="videoSeekBar"
        type="range"
        min="0"
        max={totalSegmentsDuration}
        value={mapVideoTimeToSeekBar(currentTimeState)}
        onChange={handleSeek}
        step=".000001"
      />
      <div className="time-remaining-wrapper">
        <span className="time-remaining">-{formatTime(remainingTime)}</span>
      </div>
    </div>
  );
};

export default VideoEditTest;
